import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/hooks';
import { addBreadcrumbTextReplacement } from '../../store/appSlice';
import BacktestServices from '../../services/backtestServices';
import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import ResponsiveGridWrapper from '../../components/dashboardContent/ResponsiveGridWrapper';
import {
  ArrowUturnLeftIcon,
  DocumentChartBarIcon,
  FolderIcon
} from '@heroicons/react/24/outline';
import { type ApiReqStateModel } from '../../models/ApiReqStateModel';
import { replaceTechnicalTerms, scrollToTop } from '../../utils/utils';
import { PageRoutes } from '../../enums/enums';
import type { BacktestTreeResponse } from '../../models/BacktestModel';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import { Alert } from '@mui/material';
import ResourceUnauthorizedOrDoesNotExist from '../../components/alerts/ResourceUnauthorizedOrDoesNotExist';

interface State {
  gridFormat: 'gallery' | 'list',
  treeReqRes?: BacktestTreeResponse;
  treeReqState: ApiReqStateModel;
}

export default function BacktestIndex() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [state, setState] = useState<State>({
    gridFormat: 'gallery',
    treeReqState: {
      isError: false,
      isLoading: true,
    },
  });
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { gridFormat, treeReqRes, treeReqState } = state;

  useEffect(() => {
    const pathArray = location.pathname?.split('/').slice(3);

    updateState({ treeReqState: { ...treeReqState, isLoading: true }, treeReqRes: undefined });
    scrollToTop();

    (async () => {
      try {
        const res = await BacktestServices.getTreeItems(pathArray);
        updateState({
          treeReqState: { isLoading: false, isError: false },
          treeReqRes: res,
          gridFormat: res.itemsLevel === 'backtest' ? 'list' : 'gallery',
        });

        // Add the path name to the breadcrumb
        pathArray.map((path) => {
          dispatch(addBreadcrumbTextReplacement([path, replaceTechnicalTerms(path)]));
          return path;
        });
      } catch (e) {
        updateState({
          treeReqState: { isLoading: false, isError: true },
          treeReqRes: undefined,
        });
      }
    })();
    }, [dispatch, location]);

  return (
    <Page>
      <PageModule state={treeReqState} errorMessage={<ResourceUnauthorizedOrDoesNotExist/>}>
        <PageModuleTitle
          title={replaceTechnicalTerms(treeReqRes?.value) ?? ''}
          subtitle={replaceTechnicalTerms(treeReqRes?.level)}
        />
        <ResponsiveGridWrapper type={gridFormat}>
          <>
            {treeReqRes?.items &&
              treeReqRes.itemsLevel !== 'allocation' &&
              !treeReqState.isLoading && (
                <ActionLinkButton
                  label={
                    (replaceTechnicalTerms(treeReqRes?.level) ?? '') + ' (all)'
                  }
                  to={`/${PageRoutes.DASHBOARD_OUTLET}/${
                    PageRoutes.BACKTEST_OUTLET
                  }${
                    location.pathname.split('/').length > 4 ? '/' : ''
                  }${location.pathname.split('/').slice(3, -1).join('/')}`}
                  type={gridFormat}
                >
                  <ArrowUturnLeftIcon />
                </ActionLinkButton>
              )}
            {treeReqRes?.items?.map((treeItem) => (
              <ActionLinkButton
                key={treeItem.name}
                label={(replaceTechnicalTerms(treeItem.name) ?? '') + (treeItem.backtestCount ? ` (${treeItem.backtestCount.toString()})` : '')}
                sublabel={replaceTechnicalTerms(treeReqRes?.itemsLevel)}
                to={`${location.pathname}/${treeItem.name}`}
                type={gridFormat}
              >
                <>
                  {treeReqRes?.itemsLevel === 'backtest' && (
                    <DocumentChartBarIcon />
                  )}
                  {treeReqRes?.itemsLevel !== 'backtest' && <FolderIcon />}
                </>
              </ActionLinkButton>
            ))}
            {
              treeReqRes?.items.length === 0 && <Alert className={'mt-2'} severity={'info'}>There was no backtest found matching the requested criteria</Alert>
            }
          </>
        </ResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}
