import React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import LoginForm from './LoginForm';
import TwoFaForm from './TwoFaForm';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import ImageWrapper from '../../components/ImageWrapper';

export default function Login() {
  const authContext = useAppSelector((state) => state.authentication);

  return (<div>
    <div className={'h-[calc(6rem+2px)]'}></div>
    <Page>
      <PageModule gap={'lg'}>
        <div className={'flex items-center h-[60vh]'}>
          <div className={'flex flex-col gap-4 w-full max-w-[16rem] m-auto'}>
            <a href={'/'} className={'m-auto'}>
              <ImageWrapper
                alt={'neXDos GmbH'}
                src={'/images/logo.png'}
                className={'h-7 my-1'}
              />
            </a>
            <div></div>
            {
              authContext.twoFaRequired && authContext.user &&
              <TwoFaForm email={authContext.user.email} password={authContext.password} />
            }
            {
              !authContext.twoFaRequired && <LoginForm />
            }
          </div>
        </div>
      </PageModule>
    </Page>
  </div>);
}
