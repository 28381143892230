import React, { useEffect } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import PageNotFound from './pages/PageNotFound';
import PublishingOutlet from './pages/publishing/PublishingOutlet';
import ArticlesEntry from './pages/news/articles/ArticlesEntry';
import NewsOutlet from './pages/news/NewsOutlet';
import ArticlesOutlet from './pages/news/articles/ArticlesOutlet';
import ArticlesIndex from './pages/news/articles/ArticlesIndex';
import PublishingIndex from './pages/publishing/PublishingIndex';
import CompanyOutlet from './pages/companies/CompanyOutlet';
import CompanyIndex from './pages/companies/CompanyIndex';
import StockEntry from './pages/companies/StockEntry';
import Login from './pages/login';
import { PageRoutes } from './enums/enums';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import GlobalLoaderMask from './components/general/GlobalLoaderMask';
import NonRequireAuth from './components/PageGates/NonRequireAuth';
import RequireAuth from './components/PageGates/RequireAuth';
import {initJwt, logout} from './store/authenticationSlice';
import UserManagementOutlet from './pages/userManagement/UserManagementOutlet';
import UserManagementIndex from './pages/userManagement/UserManagementIndex';
import CreateUser from './pages/userManagement/CreateUser';
import UserSecurityAndPrivacy from './pages/userManagement/UserSecurityAndPrivacy';
import GlobalSnackbar from './components/GlobalSnackbar';
import AuthenticationService from './services/authenticationService';
import axios from 'axios';
import EditUserDetail from './pages/userManagement/EditUserDetail';
import AdminRoute from './components/PageGates/AdminRoute';
import UsersOverview from './pages/userManagement/UsersOverview';
import SingleUserIndex from './pages/userManagement/userHub/UserHubIndex';
import ErrorBoundary from './components/ErrorBoundary';
import BacktestOutlet from './pages/backtest/BacktestOutlet';
import BacktestIndex from './pages/backtest/BacktestIndex';
import OverviewOutlet from './pages/userManagement/OverviewOutlet';
import UserHubOutlet from './pages/userManagement/userHub/UserHubOutlet';
import FactsheetOutlet from './pages/factsheet/FactsheetOutlet';
import FactsheetIndex from './pages/factsheet/FactsheetIndex';
import BacktestEntry from './pages/backtest/BacktestEntry';
import BacktestManagement from './pages/userManagement/userHub/BacktestManagement';

const wrapNonRequiredRoute = (route: JSX.Element) => (
  <NonRequireAuth>{route}</NonRequireAuth>
);
const wrapRequiredRoute = (route: JSX.Element) => (
  <RequireAuth>{route}</RequireAuth>
);

function App() {
  const dispatch = useAppDispatch();
  const { processLoading, snackbar } = useAppSelector(
    (state) => state.dashboard
  );

  axios.interceptors.request.use(
    (config) => {
      // Use the JWT from local storage because the one from the slice is often stale
      if (localStorage.jwt) {
        // Only Update header when there is a JWT in the authentication slice
        config.headers.set('Authorization', `Bearer ${localStorage.jwt}`);
      }
      return config;
    },
    async (error) => {
      return await Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (r) => {
      if (r.data?.errors) {
        throw new Error('An unexpected error occurred');
      }
      return r;
    },
    async (j) => {
      if (j.response && j.response.status === 403 &&
        (j.response.data.detail === 'Signature expired' || j.response.data.detail === 'Malformed token')) {
        dispatch(initJwt(''));
        dispatch(logout());
        j.message = 'You have been logged out due to inactivity, please log in again';
      }
      if (j.code === 'ERR_NETWORK') {
        j.message = 'The server is down at the moment, please try again later';
      }
      if (j.response?.data?.message) {
        j.message = j.response.data.message;
      }
      if (j.response?.data?.errors) {
        j.message = j.response.data.errors[0]?.message ?? 'An error occurred';
      }
      return await Promise.reject(j);
    }
  );

  useEffect(() => {
    const logUserIfPossible = () => {
      const localStorageJwt = localStorage.getItem('jwt');
      if (localStorageJwt) {
        // Check if token is still valid
        AuthenticationService.refreshToken(localStorageJwt)
          .then((jwt) => {
            dispatch(initJwt(jwt));
          }).catch(() => {
            dispatch(initJwt(''));
        });
      } else {
        dispatch(initJwt(''));
      }
    };

    logUserIfPossible();
  }, [dispatch]);

  return (
    <ErrorBoundary>
      <GlobalLoaderMask isVisible={processLoading > 0}/>
      <GlobalSnackbar isVisible={snackbar.isVisible}/>
      <Routes>
        <Route path={''} element={wrapNonRequiredRoute(<Login />)} />
        <Route
          path={PageRoutes.LOGIN}
          element={wrapNonRequiredRoute(<Login />)}
        />
        <Route
          path={PageRoutes.DASHBOARD_OUTLET}
          element={wrapRequiredRoute(<Dashboard />)}
        >
          <Route path={PageRoutes.DASHBOARD_HOME} element={<Navigate to={PageRoutes.NEWS_OUTLET} replace />} />
          <Route path={PageRoutes.COMPANIES_OUTLET} element={<CompanyOutlet />}>
            <Route
              path={PageRoutes.COMPANIES_HOME}
              element={<CompanyIndex />}
            />
            <Route path={':identifier'} element={<StockEntry />} />
          </Route>
          <Route path={PageRoutes.NEWS_OUTLET} element={<NewsOutlet />}>
            <Route path={PageRoutes.NEWS_HOME} element={<ArticlesIndex />} />
            <Route
              path={PageRoutes.NEWS_ARTICLES_OUTLET}
              element={<ArticlesOutlet />}
            >
              <Route
                path={PageRoutes.NEWS_ARTICLES_HOME}
                element={<Navigate to={`/${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.NEWS_OUTLET}`} replace />}
              />
              <Route path={':slug'} element={<ArticlesEntry />} />
            </Route>
          </Route>
          <Route path={PageRoutes.PUBLISHING_OUTLET} element={<PublishingOutlet />}>
            <Route
              path={PageRoutes.PUBLISHING_HOME}
              element={<PublishingIndex />}
            />
            <Route
                path={PageRoutes.PUBLISHING_OUTLET}
                element={<PublishingOutlet />}
              >
            <Route
              path={PageRoutes.PUBLISHING_HOME}
              element={<PublishingIndex />}
            />
          </Route>
          </Route>
          <Route path={PageRoutes.BACKTEST_OUTLET} element={<BacktestOutlet />}>
            <Route path={PageRoutes.BACKTEST_HOME} element={<BacktestIndex />} />
            <Route path={':allocation'} element={<BacktestIndex />} />
            <Route path={':allocation/:structure'} element={<BacktestIndex />} />
            <Route path={':allocation/:structure/:style'} element={<BacktestIndex />} />
            <Route path={':allocation/:structure/:style/:weight'} element={<BacktestIndex />} />
            <Route path={':allocation/:structure/:style/:weight/:backtestId'} element={<BacktestEntry />} />
          </Route>
          <Route path={PageRoutes.FACTSHEET_OUTLET} element={<FactsheetOutlet />}>
            <Route path={PageRoutes.FACTSHEET_HOME} element={<FactsheetIndex />} />
          </Route>
          <Route path={PageRoutes.USERS_OUTLET} element={<AdminRoute><UserManagementOutlet /></AdminRoute>}>
            <Route
              path={PageRoutes.USERS_HOME}
              element={<UserManagementIndex />}
            />
            <Route path={PageRoutes.USERS_OVERVIEW_OUTLET} element={<OverviewOutlet/>}>
              <Route
                path={PageRoutes.USERS_OVERVIEW_INDEX}
                element={<UsersOverview />}
              />
              <Route path={PageRoutes.USERS_USER_HUB_OUTLET} element={<UserHubOutlet/>}>
                <Route path={PageRoutes.USERS_USER_HUB_INDEX} element={<SingleUserIndex/>}/>
                <Route path={PageRoutes.USERS_EDIT} element={<EditUserDetail/>}/>
                <Route path={PageRoutes.USERS_DELETE} element={<UserSecurityAndPrivacy />}/>
                <Route path={PageRoutes.USERS_BACKTEST} element={<BacktestManagement/>}/>
              </Route>
            </Route>
            <Route path={PageRoutes.USERS_NEW} element={<CreateUser />} />
          </Route>
        </Route>
        <Route path={PageRoutes.PAGE_NOT_FOUND} element={<PageNotFound />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
