import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Link, useLocation } from 'react-router-dom';
import { updatePageTitle } from '../../store/appSlice';
import {ArrowForwardIos} from '@mui/icons-material';

interface BreadcrumbsProps {
  isContentOverflowing: boolean;
}

interface State {
  pathArray: string[];
  prettyPathArray: string[];
}

export default function AutoBreadcrumbs({
  isContentOverflowing,
}: BreadcrumbsProps) {
  const { sidebar } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathName: string = location.pathname;
  const [state, setState] = useState<State>({
    pathArray: [],
    prettyPathArray: [],
  });
  const { pathArray, prettyPathArray } = state;
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const replacementTextDict = useAppSelector(
    (state) => state.dashboard.breadcrumbReplacementTextDict
  );
  const replacementTextDictTS = useAppSelector(
    (state) => state.dashboard.breadcrumbReplacementTextDictTimestamp
  );
  const dashboardH = useAppSelector((state) => state.dashboard.dim.h);

  const scrollToEnd = () => {
    // This is a very simplistic way, with a timeout, which definitely could be improved in the future
    const element = document.querySelector('#breadcrumbs');
    if (element) {
      setTimeout(() => {
        element.scrollLeft = element.scrollWidth;
      }, 100);
    }
  };

  const computePrettyPath = useCallback(
    (pathArr: string[]) => {
      const tempArr: string[] = [];
      for (const path of pathArr) {
        if (path in replacementTextDict) {
          tempArr.push(replacementTextDict[path]);
        } else {
          tempArr.push('');
        }
      }
      updateState({ prettyPathArray: tempArr });
      scrollToEnd();
      return tempArr[tempArr.length - 1];
    },
    [replacementTextDict]
  );

  useEffect(() => {
    const tempArr = pathName.split('/').slice(2);
    updateState({ pathArray: tempArr });
    const pageTitle = computePrettyPath(tempArr);
    if (pageTitle || tempArr.length === 1) {
      dispatch(updatePageTitle(pageTitle));
    }
    scrollToEnd();
  }, [
    pathName,
    replacementTextDictTS,
    dispatch,
    computePrettyPath,
    sidebar.isExpanded,
  ]);

  return (
    <>
      {/* {prettyPathArray.join('') !== '' && pathArray?.length > 0 && ( */}
        <>
          <div
            id={'breadcrumbs'}
            style={{ top: `${window.innerHeight - dashboardH}px` }}
            className={
              'text-teal-1 px-4 py-3 sticky bg-white overflow-x-auto right-0 ' +
              `${isContentOverflowing ? 'border-b' : ''} ` +
              'font-bold uppercase text-sm z-10 min-h-[calc(2.5rem+1px)]'
            }>
            <div className={'items-top gap-1 flex pr-4 w-fit'}>
              {/* DESKTOP */}
              {pathArray?.map((path, ix) => {
                if (prettyPathArray[ix] === '') return <span key={ix} className={'-mr-1'}></span>;
                return (<span key={ix}>
                  {prettyPathArray[ix] && (
                    <span className={'flex gap-1 items-top'}>
                      {ix !== 0 && (
                        <ArrowForwardIos/>
                      )}
                      <Link
                        to={pathArray.slice(0, ix + 1).join('/')}
                        className={`inline mt-[-0.2rem] whitespace-nowrap flex-nowrap ${
                          ix + 1 === pathArray.length ? 'text-teal-ppt-1' : ''
                        }`}
                      >
                        {prettyPathArray[ix]}
                      </Link>
                    </span>
                  )}
                </span>);
              })}
            </div>
          </div>
        </>
      {/* )} */}
    </>
  );
}
