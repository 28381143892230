import React, { useEffect, useState } from 'react';
import { type PaginationMeta } from '../models/GhostPostModel';
import { KeyboardBackspace, KeyboardTab } from '@mui/icons-material';
import CustomTextField from './CustomTextField';
import { InputAdornment } from '@mui/material';
import { scrollToTop } from '../utils/utils';
import StatefulModuleDisabler from './StatefulModuleDisabler';

interface Props {
  pagination: PaginationMeta | undefined;
  retrievePageCallback: (pageNumber: number) => void;
  className?: string;
  maxPageListing?: number;
  prevLabel?: string | JSX.Element;
  nextLabel?: string | JSX.Element;
  firstLabel?: string | JSX.Element;
  lastLabel?: string | JSX.Element;
  disabled?: boolean;
}

interface State {
  pageInput: number;
}

export default function GenericPaginator({
  pagination,
  retrievePageCallback,
  className,
  maxPageListing,
  prevLabel,
  nextLabel,
  firstLabel,
  lastLabel,
  disabled,
}: Props) {
  if (!maxPageListing) {
    // Should be an odd number
    maxPageListing = 7;
  }

  const [state, setState] = useState<State>({ pageInput: 1 });
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { pageInput } = state;

  const pageHandler = (
    e:
      | React.MouseEvent<HTMLSpanElement | SVGSVGElement>
      | React.KeyboardEvent<HTMLDivElement>
      | React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
    pageNumber: number | null
  ) => {
    e.stopPropagation();
    if (!pageNumber) return;
    retrievePageCallback(pageNumber);
    scrollToTop();
  };

  useEffect(() => {
    updateState({ pageInput: pagination?.page ?? 1 });
  }, [pagination?.page]);

  return (
    <>
      {pagination && pagination.pages > 1 && (
        <StatefulModuleDisabler disabled={disabled ?? false}>
          <div
            className={`flex flex-wrap gap-6 items-center justify-center relative ${
              className ?? ''
            }`}
          >
            {pagination.pages > maxPageListing && (
              <PaginatorButton
                onClick={(e) => {
                  pageHandler(e, 1);
                }}
                disabled={!pagination.prev}
              >
                {firstLabel ?? (
                  <KeyboardTab
                    className={'mb-1'}
                    style={{ transform: 'rotate(180deg)' }}
                  />
                )}
              </PaginatorButton>
            )}
            <PaginatorButton
              onClick={(e) => {
                pageHandler(e, pagination.prev);
              }}
              disabled={!pagination.prev}
            >
              {prevLabel ?? <KeyboardBackspace className={'mb-1'} />}
            </PaginatorButton>
            {pagination.pages > maxPageListing && (
              <div className={'w-24'}>
                <CustomTextField
                  label={'Page'}
                  value={pageInput}
                  type={'number'}
                  // helperText={'Hit enter to update'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position={'end'}
                        className={'mt-4'}
                      >{`/ ${pagination.pages}`}</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    updateState({ pageInput: Number.parseInt(e.target.value) });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      (document.activeElement as HTMLElement).blur(); // Blur all input, yes this is hacky
                    }
                  }}
                  onBlur={(e) => {
                    pageHandler(e, Number.parseInt(e.target.value));
                  }}
                />
              </div>
            )}
            {pagination.pages <= maxPageListing &&
              Array(pagination.pages)
                .fill(undefined)
                .map((e, ix) => ix)
                .slice(
                  pagination.pages - pagination.page <
                  Math.floor(maxPageListing / 2) &&
                  pagination.pages > maxPageListing
                    ? pagination.pages - maxPageListing
                    : pagination.pages <= maxPageListing
                      ? 0
                      : Math.max(
                        0,
                        pagination.page - (Math.floor(maxPageListing / 2) + 1)
                      ),
                  Math.max(
                    maxPageListing,
                    pagination.page + Math.floor(maxPageListing / 2)
                  )
                )
                .map((e, ix) => (
                  <PaginatorButton
                    key={ix}
                    onClick={(ev) => {
                      pageHandler(ev, e + 1);
                    }}
                    isSelected={e + 1 === pagination.page}
                  >
                    {(e + 1).toString()}
                  </PaginatorButton>
                ))}
            <PaginatorButton
              onClick={(e) => {
                pageHandler(e, pagination.next);
              }}
              disabled={!pagination.next}
            >
              {nextLabel ?? (
                <KeyboardBackspace
                  className={'mb-1'}
                  style={{ transform: 'rotate(180deg)' }}
                />
              )}
            </PaginatorButton>
            {pagination.pages > maxPageListing && (
              <PaginatorButton
                onClick={(e) => {
                  pageHandler(e, pagination.pages);
                }}
                disabled={!pagination.next}
              >
                {lastLabel ?? <KeyboardTab className={'mb-1'} />}
              </PaginatorButton>
            )}
          </div>
        </StatefulModuleDisabler>
      )}
    </>
  );
}

interface ButtonProps {
  children: JSX.Element | string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
  isSelected?: boolean;
}

function PaginatorButton({
  children,
  onClick,
  disabled,
  isSelected,
}: ButtonProps) {
  return (
    <a
      onClick={onClick}
      className={
        `m-0 ${
          disabled ? 'pointer-events-none opacity-30' : 'cursor-pointer'
        } ` + `${isSelected ? 'text-teal-ppt-1 font-heavy' : 'font-normal'}`
      }
    >
      {children}
    </a>
  );
}
