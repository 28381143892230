import React, { useEffect, useState } from 'react';
import GenericStatisticTableTile from '../../components/GenericStatisticTableTile';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { colorCycler } from '../../utils/utils';
import GenericPlot from '../../components/GenericPlot';
import CustomTextField from '../../components/CustomTextField';
import GenericDateRangeSelector from '../../components/GenericDateRangeSelector';
import type {
  AvgAnnualTotalReturns,
  CalendarYearlyReturns,
  DownMarketPerformances,
  DownQuartersPerformance,
  Fees,
  GrowthInvestment,
  HeaderInfo,
  MarketCyclePerformance,
  SectorAllocation,
  TopPositions,
} from '../../models/FactsheetModel';
import FactsheetServices from '../../services/factsheetServices';
import GenericTableWrapper from '../../components/GenericTableWrapper';
import DisclaimerBox from '../../components/DisclaimerBox';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import { formatDate } from '../../helpers/helper';
import TabsXd from '../../components/layout/TabsXd';
import ButtonXd from '../../components/dashboardContent/ButtonXd';
import { ArrowForward } from '@mui/icons-material';
import PageModuleForm from '../../components/layout/PageModuleForm';
import DataNotAvailable from '../../components/alerts/DataNotAvailable';
import { type ApiReqStateModel } from '../../models/ApiReqStateModel';
import StatefulApiResponseContainer from '../../components/StatefulApiResponseContainer';
import TabPanelXd from '../../components/layout/TabPanelXd';
import SingleRowTable from '../../components/data/SingleRowTable';
import SingleRowTableItem from '../../components/data/SingleRowTableItem';

interface State {
  // Values needed for DatePicker
  start?: number;
  end?: number;
  iStart?: number;
  iEnd?: number;
  hasValidationErrors?: boolean;
  headerValues?: HeaderInfo;
  headerValuesReqState: ApiReqStateModel;
  avgAnnualTotalReturnsQTR?: AvgAnnualTotalReturns;
  avgAnnualTotalReturnsQTRReqState?: ApiReqStateModel;
  avgAnnualTotalReturnsMTR?: AvgAnnualTotalReturns;
  avgAnnualTotalReturnsMTRReqState: ApiReqStateModel;
  avgAnnualTotalReturnsDTR?: AvgAnnualTotalReturns;
  avgAnnualTotalReturnsDTRReqState: ApiReqStateModel;
  marketCyclePerformance2018?: MarketCyclePerformance;
  marketCyclePerformance2018ReqState?: ApiReqStateModel;
  marketCyclePerformance2020?: MarketCyclePerformance;
  marketCyclePerformance2020ReqState: ApiReqStateModel;
  calendarYearlyReturns?: CalendarYearlyReturns;
  calendarYearlyReturnsReqState?: ApiReqStateModel;
  growthInvestment?: GrowthInvestment;
  growthInvestmentReqState?: ApiReqStateModel;
  downMarketPerformances?: DownMarketPerformances;
  downMarketPerformancesReqState?: ApiReqStateModel;
  downQuartersPerformance?: DownQuartersPerformance;
  downQuartersPerformanceReqState: ApiReqStateModel;
  topPositions?: TopPositions;
  topPositionsReqState: ApiReqStateModel;
  fees?: Fees;
  feesReqState?: ApiReqStateModel;
  sectorAllocation?: SectorAllocation;
  sectorAllocationReqState?: ApiReqStateModel;
  disclaimer?: { disclaimer: string };
  disclaimerReqState?: ApiReqStateModel;
}

export default function FactsheetIndex() {
  const [state, setState] = useState<State>({
    headerValuesReqState: { isLoading: false, isError: false },
    avgAnnualTotalReturnsMTRReqState: { isLoading: true, isError: false },
    avgAnnualTotalReturnsDTRReqState: { isLoading: true, isError: false },
    marketCyclePerformance2020ReqState: { isLoading: false, isError: false },
    downQuartersPerformanceReqState: { isLoading: false, isError: false },
    topPositionsReqState: { isLoading: false, isError: false },
  });
  const {
    headerValues,
    avgAnnualTotalReturnsQTR,
    avgAnnualTotalReturnsMTR,
    avgAnnualTotalReturnsDTR,
    marketCyclePerformance2018,
    marketCyclePerformance2020,
    calendarYearlyReturns,
    growthInvestment,
    downMarketPerformances,
    downQuartersPerformance,
    fees,
    topPositions,
    sectorAllocation,
    disclaimer,
  } = state;

  // Recent date value is used for titles
  const annualReturnsDate = formatDate(new Date(), {
    weekday: undefined,
    hour: undefined,
    minute: undefined,
    second: undefined,
    month: 'short',
    timeZoneName: undefined,
  });

  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };

  // Binds the parameterised functions
  const getAvgAnnualTotalReturnValues =
    FactsheetServices.getAvgAnnualTotalReturnValues.bind(FactsheetServices);
  const getMarketCyclePerformanceValues =
    FactsheetServices.getMarketCyclePerformanceValues.bind(FactsheetServices);

  // The data that will be fetched is grouped for a better readability
  const actions = [
    {
      action: FactsheetServices.getHeaderValues.bind(FactsheetServices),
      stateKey: 'headerValues',
    },
    {
      action: async () => await getAvgAnnualTotalReturnValues('Q'),
      stateKey: 'avgAnnualTotalReturnsQTR',
    },
    {
      action: async () =>
        await getMarketCyclePerformanceValues('13.07.2007', '9.03.2009'),
      stateKey: 'marketCyclePerformance2018',
    },
    {
      action: async () =>
        await getMarketCyclePerformanceValues('20.02.2020', '23.03.2020'),
      stateKey: 'marketCyclePerformance2020',
    },
    {
      action: FactsheetServices.getFactsheetDisclaimer.bind(FactsheetServices),
      stateKey: 'disclaimer',
    },
    {
      action:
        FactsheetServices.getCalendarYearlyReturns.bind(FactsheetServices),
      stateKey: 'calendarYearlyReturns',
    },
    {
      action: FactsheetServices.getGrowthInvestment.bind(FactsheetServices),
      stateKey: 'growthInvestment',
    },
    {
      action:
        FactsheetServices.getDownMarketPerformances.bind(FactsheetServices),
      stateKey: 'downMarketPerformances',
    },
    {
      action:
        FactsheetServices.getDownQuartersPerformance.bind(FactsheetServices),
      stateKey: 'downQuartersPerformance',
    },
    {
      action: FactsheetServices.getTopPositions.bind(FactsheetServices),
      stateKey: 'topPositions',
    },
    {
      action: FactsheetServices.getSectorAllocation.bind(FactsheetServices),
      stateKey: 'sectorAllocation',
    },
    {
      action: FactsheetServices.getFees.bind(FactsheetServices),
      stateKey: 'fees',
    },
    {
      action: async () => await getAvgAnnualTotalReturnValues('M'),
      stateKey: 'avgAnnualTotalReturnsMTR',
    },
    {
      action: async () => await getAvgAnnualTotalReturnValues('D'),
      stateKey: 'avgAnnualTotalReturnsDTR',
    },
  ];

  const fetchData = async () => {
    // dispatch(updateProcessLoading(true));
    for (let i = 0; i < actions.length; i++) {
      const { action, stateKey } = actions[i];
      updateState({
        [stateKey + 'ReqState']: { isLoading: true, isError: false },
      });
      try {
        const data = await action();
        updateState({
          [stateKey]: data,
          [stateKey + 'ReqState']: { isLoading: false, isError: false },
        });
      } catch (error) {
        updateState({
          [stateKey + 'ReqState']: { isLoading: false, isError: true },
        });
      }
    }
    // dispatch(updateProcessLoading(false));
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  // Values to decide with tabs are selected
  const [totalReturnsValue, setTotalReturnsValue] =
    React.useState('totalReturns1');
  const [portfolioAllocationValue, setPortfolioAllocationValue] =
    React.useState('portfolioAllocation1');

  // TODO: Risk Management section will be added later
  // const [riskManagementValue, setRiskManagementValue] = React.useState('riskManagement1');

  // Handles tab changes
  const handleTotalReturnsChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTotalReturnsValue(newValue);
  };

  // TODO: Risk Management section will be added later
  /* const handleRiskManagementChange = (event: React.SyntheticEvent, newValue: string) => {
      setRiskManagementValue(newValue);
  }; */

  const handlePortolioAllocationChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setPortfolioAllocationValue(newValue);
  };

  return (
    <Page>
      <PageModule
        background={<div className={'bg-slate-50'} />}
        state={state.headerValuesReqState}
        displayWhen={!!headerValues}
      >
        <PageModuleTitle title={'Factsheet'} />
        <div className={'grid grid-cols-1 sm:grid-cols-3 gap-8'}>
          <FactsheetNuggets topic={'Why invest in dividend value'}>
            <p>
              Small/mid-cap value fund (generally market caps up to $15 billion)
              that invests primarily in dividend-paying companies with strong
              business fundamentals and attractive prospects.
            </p>
          </FactsheetNuggets>
          <div className={'flex flex-col gap-2'}>
            <GenericStatisticTableTile
              label={'Small/Mid-Cap'}
              primaryValue={headerValues?.small_mid_cap ?? ''}
              secValue={'Avg. Market Cap'}
              secLabel={'2'}
            />
            <GenericStatisticTableTile
              label={'Domestic'}
              primaryValue={headerValues?.domestic_ratio ?? ''}
              secValue={'2022 Annual Turnover Rate'}
            />
          </div>
          <FactsheetNuggets topic={'Fund materials'}>
            <p>
              Small/mid-cap value fund (generally market caps up to $15 billion)
              that invests primarily in dividend-paying companies with strong
              business fundamentals and attractive prospects.
            </p>
          </FactsheetNuggets>
        </div>
        <FactsheetNuggets topic={'Portfolio management'}>
          <p>
            Capital preservation means as much to us as capital appreciation.
            Our absolute return orientation fosters realistic expectations for
            the stocks we buy. We have a long-term investment horizon for the
            companies that we own and the portfolio itself. —Chuck Royce
          </p>
        </FactsheetNuggets>
      </PageModule>
      <PageModule
        state={state.avgAnnualTotalReturnsQTRReqState}
        displayWhen={!!avgAnnualTotalReturnsQTR}
      >
        <PageModuleTitle
          level={'h2'}
          title={'Average Annual Total Returns (%)'}
        />
        <TabContext value={totalReturnsValue}>
          <TabsXd value={totalReturnsValue} onChange={handleTotalReturnsChange}>
            <Tab
              label={`Quarterly as of ${annualReturnsDate}`}
              value='totalReturns1'
            />
            <Tab
              label={`Monthly as of ${annualReturnsDate}`}
              value='totalReturns2'
            />
            <Tab
              label={`Daily as of ${annualReturnsDate}`}
              value='totalReturns3'
            />
          </TabsXd>
          <TabPanelXd value='totalReturns1'>
            <SingleRowTable>
              {Object.keys(avgAnnualTotalReturnsQTR?.nexdos ?? {}).map(
                (e) => {
                  const o = avgAnnualTotalReturnsQTR?.nexdos;
                  let val: string | number = '';
                  if (o) {
                    val = o[e as keyof typeof o];
                  }
                  return (
                    <SingleRowTableItem key={e} title={e} val={val}/>
                  );
                }
              )}
            </SingleRowTable>
          </TabPanelXd>
          <TabPanelXd value='totalReturns2'>
            <StatefulApiResponseContainer
              state={state.avgAnnualTotalReturnsMTRReqState}
              displayWhen={!!avgAnnualTotalReturnsMTR}
            >
              <SingleRowTable>
                {Object.keys(avgAnnualTotalReturnsMTR?.nexdos ?? {}).map(
                  (e) => {
                    const o = avgAnnualTotalReturnsMTR?.nexdos;
                    let val: string | number = '';
                    if (o) {
                      val = o[e as keyof typeof o];
                    }
                    return (
                      <SingleRowTableItem key={e} title={e} val={val}/>
                    );
                  }
                )}
              </SingleRowTable>
            </StatefulApiResponseContainer>
          </TabPanelXd>
          <TabPanelXd value='totalReturns3'>
            <StatefulApiResponseContainer
              state={state.avgAnnualTotalReturnsDTRReqState}
              displayWhen={!!avgAnnualTotalReturnsDTR}
            >
              <SingleRowTable>
                {Object.keys(avgAnnualTotalReturnsDTR?.nexdos ?? {}).map(
                  (e) => {
                    const o = avgAnnualTotalReturnsDTR?.nexdos;
                    let val: string | number = '';
                    if (o) {
                      val = o[e as keyof typeof o];
                    }
                    return (
                      <SingleRowTableItem key={e} title={e} val={val}/>
                    );
                  }
                )}
              </SingleRowTable>
            </StatefulApiResponseContainer>
          </TabPanelXd>
        </TabContext>
      </PageModule>
      <PageModule
        state={state.marketCyclePerformance2018ReqState}
        displayWhen={
          !!marketCyclePerformance2018 || !!marketCyclePerformance2020
        }
      >
        <div className={'grid grid-cols-1 lg:grid-cols-2 gap-8'}>
          <div className={'flex flex-col gap-8'}>
            <GenericPlot
              modalTitle={'Market Cycle Performance (2018)'}
              data={Object.entries(marketCyclePerformance2018?.data ?? {}).map(
                ([key, value]) => {
                  const parsedData = JSON.parse(value);
                  const nestedParsedData =
                    parsedData[key === 'nexdos' ? 'nav' : 'price'];
                  const x = Object.keys(nestedParsedData).map(
                    (d) => new Date(Number(d))
                  );
                  const y: number[] = Object.values(nestedParsedData);

                  return {
                    x,
                    y,
                    type: 'scatter',
                    name: key.charAt(0).toUpperCase() + key.slice(1),
                    line: { color: colorCycler(key === 'nexdos' ? 0 : 1) },
                    yaxis: '1',
                  };
                }
              )}
            />
            <div className={'flex flex-col gap-2'}>
              <GenericStatisticTableTile
                label={'Nexdos'}
                primaryValue={
                  typeof marketCyclePerformance2018?.return.nexdos === 'number'
                    ? marketCyclePerformance2018?.return.nexdos.toFixed(3)
                    : ''
                }
              />
              <GenericStatisticTableTile
                label={'Benchmark'}
                primaryValue={
                  typeof marketCyclePerformance2018?.return.benchmark ===
                  'number'
                    ? marketCyclePerformance2018?.return.benchmark.toFixed(3)
                    : ''
                }
              />
            </div>
          </div>
          <StatefulApiResponseContainer
            state={state.marketCyclePerformance2020ReqState}
            displayWhen={!!marketCyclePerformance2020}
          >
            <div className={'flex flex-col gap-8'}>
              <GenericPlot
                modalTitle={'Market Cycle Performance (2020)'}
                data={Object.entries(
                  marketCyclePerformance2020?.data ?? {}
                ).map(([key, value]) => {
                  const parsedData = JSON.parse(value);
                  const nestedParsedData =
                    parsedData[key === 'nexdos' ? 'nav' : 'price'];
                  const x = Object.keys(nestedParsedData).map(
                    (d) => new Date(Number(d))
                  );
                  const y: number[] = Object.values(nestedParsedData);

                  return {
                    x,
                    y,
                    type: 'scatter',
                    name: key.charAt(0).toUpperCase() + key.slice(1),
                    line: { color: colorCycler(key === 'nexdos' ? 0 : 1) },
                    yaxis: '1',
                  };
                })}
              />
              <div className={'flex flex-col gap-2'}>
                <GenericStatisticTableTile
                  label={'Nexdos'}
                  primaryValue={
                    typeof marketCyclePerformance2020?.return.nexdos ===
                    'number'
                      ? marketCyclePerformance2020?.return.nexdos.toFixed(3)
                      : ''
                  }
                />
                <GenericStatisticTableTile
                  label={'Benchmark'}
                  primaryValue={
                    typeof marketCyclePerformance2020?.return.benchmark ===
                    'number'
                      ? marketCyclePerformance2020?.return.benchmark.toFixed(3)
                      : ''
                  }
                />
              </div>
            </div>
          </StatefulApiResponseContainer>
        </div>
      </PageModule>
      <PageModule
        state={state.calendarYearlyReturnsReqState}
        displayWhen={!!calendarYearlyReturns}
      >
        <GenericPlot
          modalTitle={'Calendar Yearly Returns'}
          data={[
            {
              x: Object.keys(calendarYearlyReturns?.nexdos ?? {}),
              y: Object.values(calendarYearlyReturns?.nexdos ?? {}).map((val) =>
                typeof val === 'number' ? val : 0
              ),
              type: 'bar',
              name: 'Nexdos',
              marker: { color: colorCycler(3) },
            },
            {
              x: Object.keys(calendarYearlyReturns?.benchmark ?? {}),
              y: Object.values(calendarYearlyReturns?.benchmark ?? {}).map(
                (val) => (typeof val === 'number' ? val : 0)
              ),
              type: 'bar',
              name: 'Benchmark',
              marker: { color: colorCycler(6) },
            },
          ]}
        />
      </PageModule>
      <PageModule
        state={state.growthInvestmentReqState}
        displayWhen={!!growthInvestment}
      >
        <PageModuleTitle level={'h2'} title={'Growth Investment'} />
        <div className={'grid grid-cols-1 lg:grid-cols-5 gap-8'}>
          <div className={'flex flex-col gap-6 lg:col-span-2'}>
            <div className={'flex flex-col gap-2'}>
              <GenericStatisticTableTile
                label={'Nexdos'}
                primaryValue={'$44,465'}
              />
              <GenericStatisticTableTile
                label={'Russell 2500'}
                primaryValue={'$49,216'}
              />
            </div>
            <PageModuleForm>
              <PageModuleTitle level={'h3'} title={'Calculator'} />
              <CustomTextField label={'Investment Amount'} />
              <GenericDateRangeSelector
                limitWidth={false}
                onValidationError={(hasValidationErrors) => {
                  updateState({ hasValidationErrors });
                }}
                onChange={(obj) => {
                  updateState(obj);
                }}
              />
              <ButtonXd icon={<ArrowForward />} trailingIcon>
                Submit
              </ButtonXd>
            </PageModuleForm>
          </div>
          <div className={'lg:col-span-3'}>
            <GenericPlot
              modalTitle={'Growth Investment'}
              excludeHeader
              data={(() => {
                const navData =
                  JSON.parse(growthInvestment?.growth_investment ?? '{}').nav ??
                  {};
                const dates = Object.keys(navData).map(
                  (key) => new Date(Number(key))
                );
                const values = Object.values(navData).map((val) => Number(val));
                return [
                  {
                    x: dates,
                    y: values,
                    type: 'scatter',
                    name: 'Growth Investment',
                    line: {
                      color: colorCycler(7),
                    },
                  },
                ];
              })()}
            />
          </div>
        </div>
      </PageModule>
      <PageModule
        state={state.downMarketPerformancesReqState}
        displayWhen={!!downMarketPerformances}
      >
        <PageModuleTitle
          level={'h2'}
          title={'Down Market Performance Comparison'}
        />
        <p>
          The Fund outperformed the Russell 2500 during all six downturns of 15%
          or more from the index’s prior historical high since the Fund’s
          inception.
        </p>
        <GenericPlot
          modalTitle={'Down Market Performance Comparison'}
          excludeHeader={true}
          data={[
            {
              x: Object.keys(downMarketPerformances ?? {}),
              y: Object.values(downMarketPerformances ?? {}).map(
                (val) => val?.nexdos ?? 0
              ),
              type: 'bar',
              name: 'Nexdos',
              marker: { color: colorCycler(6) },
            },
            {
              x: Object.keys(downMarketPerformances ?? {}),
              y: Object.values(downMarketPerformances ?? {}).map(
                (val) => val?.benchmark ?? 0
              ),
              type: 'bar',
              name: 'Benchmark',
              marker: { color: colorCycler(9) },
            },
          ]}
        />{' '}
        <StatefulApiResponseContainer
          state={state.downQuartersPerformanceReqState}
          displayWhen={!!downQuartersPerformance}
        >
          <div className={'flex flex-col gap-6'}>
            <PageModuleTitle level={'h3'} title={'Russel 2500 Down Quarters'} />
            <GenericTableWrapper
              data={
                downQuartersPerformance
                  ? Object.fromEntries(
                    Object.entries(downQuartersPerformance)
                      .sort(([a], [b]) => b.localeCompare(a))
                      .map(([key, value]) => [
                        `${key.slice(0, 4)} - ${key.slice(4)}`,
                        value,
                      ])
                  )
                  : {}
              }
              withIndexes
            />
          </div>
        </StatefulApiResponseContainer>
      </PageModule>
      <PageModule
        state={state.sectorAllocationReqState}
        displayWhen={!!sectorAllocation}
      >
        <PageModuleTitle level={'h2'} title={'Portfolio Allocation'} />
        <div className={'grid grid-cols-1 lg:grid-cols-5 gap-8'}>
          <div className={'lg:col-span-2 flex flex-col gap-2'}>
            <GenericStatisticTableTile
              label={'Total Holdings'}
              primaryValue={'49'}
            />
            <GenericTableWrapper data={sectorAllocation ?? {}} withIndexes />
          </div>
          <div className={'lg:col-span-3'}>
            <TabContext value={portfolioAllocationValue}>
              <TabsXd
                value={portfolioAllocationValue}
                onChange={handlePortolioAllocationChange}
              >
                <Tab
                  label='QUARTERLY AS OF 03/31/23'
                  value='portfolioAllocation1'
                />
                <Tab
                  label='MONTHLY AS OF 03/31/23'
                  value='portfolioAllocation2'
                />
              </TabsXd>
              <TabPanel value='portfolioAllocation1' className={'!px-0'}>
                <StatefulApiResponseContainer
                  state={state.topPositionsReqState}
                  displayWhen={!!topPositions}
                >
                  {topPositions ? (
                    Object.entries(topPositions).map(([key, value], ix) => (
                      <div key={ix} className={'mb-1'}>
                        <GenericStatisticTableTile
                          label={key}
                          primaryValue={value.nexdos ?? ''}
                        />
                      </div>
                    ))
                  ) : (
                    <DataNotAvailable />
                  )}
                </StatefulApiResponseContainer>
              </TabPanel>
              <TabPanel value='portfolioAllocation2' className={'!px-0'}>
                <StatefulApiResponseContainer
                  state={state.topPositionsReqState}
                  displayWhen={!!topPositions}
                >
                  {topPositions ? (
                    Object.entries(topPositions).map(([key, value], ix) => (
                      <div key={ix} className={'mb-1'}>
                        <GenericStatisticTableTile
                          label={key}
                          primaryValue={value.nexdos ?? ''}
                        />
                      </div>
                    ))
                  ) : (
                    <DataNotAvailable />
                  )}
                </StatefulApiResponseContainer>
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </PageModule>
      <PageModule state={state.feesReqState} displayWhen={!!fees}>
        <PageModuleTitle level={'h2'} title={'Fees & Expenses'} />
        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-8'}>
          <div className={'flex flex-col gap-6'}>
            <PageModuleTitle
              level={'h3'}
              title={'Shareholder fees'}
              desc={'Fees paid directly from your investment'}
            />
            <div className={'flex flex-col gap-2'}>
              {fees ? (
                Object.entries(fees).map((e, ix) => {
                  return (
                    <GenericStatisticTableTile
                      key={ix}
                      label={e[0]}
                      primaryValue={e[1] ?? ''}
                    />
                  );
                })
              ) : (
                <DataNotAvailable />
              )}
            </div>
          </div>
          <div className={'flex flex-col gap-6'}>
            <div>
              <PageModuleTitle
                level={'h3'}
                title={'Annual Fund Operating Expenses'}
              />
              <p>Lorem ipsum dolor sit amet</p>
            </div>
            <div className={'flex flex-col gap-2'}>
              <GenericStatisticTableTile
                label={'Maximum sales charge (load) imposed on purchases'}
                primaryValue={'0.00'}
              />
              <GenericStatisticTableTile
                label={'Maximum deferred sales charge'}
                primaryValue={'0.00'}
              />
              <GenericStatisticTableTile
                label={'Max. sales charge imposed on reinvested dividends'}
                primaryValue={'0.00'}
              />
              <GenericStatisticTableTile
                key={0}
                label={'Redemption fee'}
                primaryValue={'1.00'}
              />
            </div>
          </div>
        </div>
      </PageModule>
      <DisclaimerBox htmlString={disclaimer?.disclaimer} />
    </Page>
  );
}

interface factsheetNuggetsProps {
  topic: string;
  children: React.ReactNode;
}

function FactsheetNuggets({ topic, children }: factsheetNuggetsProps) {
  return (
    <div className={'flex flex-col gap-2'}>
      <h4 className={'font-bold'}>{topic}</h4>
      {children}
    </div>
  );
}
