import React from 'react';
import {type TextFieldProps, TextField} from '@mui/material';

/***
 * Textfield wrapper to enforce the 'filled' variant.
 * @param props one or more properties of TextFieldProps
 * @constructor
 */
export default function CustomTextField(props: TextFieldProps) {
  const classNames = ['w-full'];

  if (!props.className?.includes('sm:w-')) {
    classNames.push('sm:w-auto');
  }

  if (props.className) {
    classNames.push(props.className);
  }

  const pr = {...props, className: classNames.join(' '), variant: 'filled'};
  return (<TextField {...pr} variant={'filled'}/>);
}