import React from 'react';
import { formatDate } from '../../helpers/helper';

interface singleRowTableItemProps {
  title: string;
  val: string | number;
}

export default function SingleRowTableItem({title, val}: singleRowTableItemProps) {
  if (typeof val === 'number') {
    val = val.toFixed(2);
  } else if (val.includes('T')) {
    val = formatDate(new Date(val), {weekday: undefined, month: 'short', hour: undefined, minute: undefined, second: undefined, timeZoneName: undefined});
  }

  return (
    <div className={'flex flex-col flex-grow text-center'}>
      <p className={'bg-gray-200 p-4 py-2 font-[600] text-sm opacity-80'}>{title.replace('_', ' ')}</p>
      <p className={'p-4 py-2'}>{val.toString()}</p>
    </div>
  );
}