import {PlusIcon, UserGroupIcon} from '@heroicons/react/24/outline';
import React from 'react';
import { PageRoutes } from '../../enums/enums';
import ResponsiveGridWrapper from '../../components/dashboardContent/ResponsiveGridWrapper';
import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';


export default function UserManagementIndex() {
  return (
    <Page>
      <PageModule>
        <PageModuleTitle title={'User Management'}/>
        <ResponsiveGridWrapper>
          <ActionLinkButton label='Create account' to={PageRoutes.USERS_NEW}>
            <PlusIcon/>
          </ActionLinkButton>
          <ActionLinkButton label='Manage accounts' to={PageRoutes.USERS_OVERVIEW_OUTLET}>
            <UserGroupIcon/>
          </ActionLinkButton>
        </ResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}
