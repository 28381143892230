import React, { useEffect, useRef, useState } from 'react';
import ImageWrapper from '../ImageWrapper';
import { Close, Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import MobileMenu from './MobileMenu';
import { disableScroll } from '../../helpers/helper';
import { updateDashboardH } from '../../store/appSlice';
import { useAppDispatch } from '../../hooks/hooks';
import {debounce} from '../../utils/utils';

interface State {
  isMenuExpanded?: boolean;
}

interface headerProps {
  mobileNavItems?: JSX.Element;
  headerFloatRightItems?: JSX.Element;
}

export default function Header({
  mobileNavItems,
  headerFloatRightItems,
}: headerProps) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({});
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { isMenuExpanded } = state;
  const headerDom = useRef<HTMLDivElement>(null);

  useEffect(() => {
    disableScroll(isMenuExpanded ?? false);
  }, [isMenuExpanded]);

  useEffect(() => {
    const doLayoutCalculations = () => {
      updateState({ isMenuExpanded: false });
      if (headerDom.current != null)
        dispatch(
          updateDashboardH(
            window.innerHeight -
              headerDom.current.getBoundingClientRect().height
          )
        );
    };
    doLayoutCalculations();
    window.addEventListener('resize', debounce(doLayoutCalculations, 0));
    return () => {
      window.removeEventListener('resize', debounce(doLayoutCalculations, 0));
    };
  }, [dispatch]);

  return (
    <>
      <div
        className={
          'sticky top-0 z-50 flex gap-0 md:gap-4 border-b bg-white items-center px-4'
        }
        id={'header'}
        ref={headerDom}
      >
        {(headerFloatRightItems != null || mobileNavItems != null) && (
        <span className={'md:hidden -ml-4'}>
            <IconButton
              disableRipple
              size={'large'}
              onClick={() => {
                updateState({ isMenuExpanded: !isMenuExpanded });
              }}
            >
              {!isMenuExpanded ? <Menu /> : <Close />}
            </IconButton>
          </span>
      )}
        <div className={'flex-grow flex py-3 justify-center md:justify-start'}>
          <a href={'/'}>
            <ImageWrapper
              alt={'neXDos GmbH'}
              src={'/images/logo.png'}
              className={'h-6 my-1'}
            />
          </a>
        </div>
        <span className={'md:hidden -mr-4 opacity-0'}>
            <IconButton
              disableRipple
              size={'large'}
            >
              <Menu/>
            </IconButton>
          </span>
        <span className={'hidden md:flex gap-4'}>{headerFloatRightItems}</span>

      </div>
      {
        <MobileMenu
          isMenuExpanded={isMenuExpanded ?? false}
          onClose={() => {
            updateState({ isMenuExpanded: false });
          }}
          mobileNavItems={mobileNavItems}
          headerDropdown={headerFloatRightItems}
        />
      }
    </>
  );
}
