import React, { useState } from 'react';
import { send2Fa } from '../../store/authenticationSlice';
import { useAppDispatch } from '../../hooks/hooks';
import AuthenticationService from '../../services/authenticationService';
import {TextField} from '@mui/material';
import PaddedButton from '../../components/general/PaddedButton';
import {ArrowForward} from '@mui/icons-material';

interface TwoFaFormProps {
  readonly email: string;
  readonly password: string;
}

export default function TwoFaForm({ email, password }: TwoFaFormProps) {
  const dispatch = useAppDispatch();
  const [code, setCode] = useState('');

  const sendCode = async () => {
    const twoFaResponse = await AuthenticationService.send2Fa(
      email,
      password,
      code
    );
    if (!twoFaResponse.hasError()) {
      dispatch(send2Fa(twoFaResponse.getData()));
    } else {
      throw new Error('Invalid OTP. Please try again.');
    }
  };

  return (
    <>
      <p>Please provide the One-Time Password (OTP) generated by your authenticator app.</p>
      <TextField
        label={'Code'}
        variant={'filled'}
        value={code}
        onChange={({ currentTarget }) => {
          setCode(currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            sendCode();
          }
        }}
      />
      <PaddedButton label={'Submit'} icon={<ArrowForward/>} trailingIcon onClick={sendCode}/>
    </>
  );
}
