import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton, { type ActionButtonProps } from './ActionButton';

interface ActionLinkButtonProps extends ActionButtonProps {
  to?: string;
  target?: '_blank' | '_self';
}

export default function ActionLinkButton({
  label,
  to,
  href,
  target,
  children,
  disabled,
  sublabel,
  type,
}: ActionLinkButtonProps) {
  const actionButton = (
    <ActionButton label={label} disabled={disabled} sublabel={sublabel} type={type}>{children}</ActionButton>
  );
  return (
    <>
      {
        !disabled &&
          <>
            {
              to && !href &&
                <Link to={to} target={target ?? '_self'}>{actionButton}</Link>
            }
            {
              href && !to &&
                <a href={href} target={target ?? '_self'}>{actionButton}</a>
            }
          </>
      }
    </>
  );
}
