import React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import PaddedButton from '../general/PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import Page from '../layout/Page';
import PageModule from '../layout/PageModule';

interface AdminRouteProps {
  children: JSX.Element;
}

export default function AdminRoute({children}: AdminRouteProps) {
  const { user } = useAppSelector((state) => state.authentication);

  return (
    <>
      {
        !user?.isAdmin &&
        <Page>
          <PageModule>
            <div className={'flex flex-col justify-center h-[70vh]'}>
              <div className={'flex flex-col gap-8 text-center'}>
                <div>
                  <h2>Sorry, you are not authorized to view this page</h2>
                  <p>If you believe this was a mistake, please contact your administrator</p>
                </div>
                <div>
                  <Link to={'/'} replace>
                    <PaddedButton label={'Go to homepage'} icon={<ArrowForward/>} trailingIcon/>
                  </Link>
                </div>
              </div>
            </div>
          </PageModule>
        </Page>
      }
      {
        user?.isAdmin &&
        children
      }
    </>
  );
}