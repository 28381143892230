import React, { useEffect } from 'react';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';
import { type ApiReqStateModel } from '../../../models/ApiReqStateModel';
import { updateState, useStateExtended } from '../../../helpers/helper';
import BacktestServices from '../../../services/backtestServices';
import { type BacktestTreeItem } from '../../../models/backtest';
import StatefulApiResponseContainer from '../../../components/StatefulApiResponseContainer';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import ButtonXd from '../../../components/dashboardContent/ButtonXd';
import { compareUnorderedList } from '../../../utils/utils';
import BacktestTreeButton from '../../../components/backtest/BacktestTreeButton';
import DataNotAvailable from '../../../components/alerts/DataNotAvailable';
import PageModuleForm from '../../../components/layout/PageModuleForm';
import { Save } from '@mui/icons-material';
import { updateSnackbar } from '../../../store/appSlice';

interface State {
  tree?: BacktestTreeItem;
  allBacktests?: string[];
  selectedNodes: string[];
  selectedNodesServer: string[];
  loadTreeState: ApiReqStateModel;
  loadSelectedState: ApiReqStateModel;
  saveNodeState: ApiReqStateModel;
  expandedNodes: string[];
}

export default function BacktestManagement() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state);
  const [state, setState, getState] = useStateExtended<State>({
    loadTreeState: {
      isLoading: true, isError: false
    },
    loadSelectedState: {
      isLoading: true, isError: false
    },
    saveNodeState: {
      isLoading: false, isError: false
    }, selectedNodes: [], selectedNodesServer: [], expandedNodes: []
  });
  const {
    loadTreeState,
    loadSelectedState,
    tree,
    selectedNodes,
    allBacktests,
    selectedNodesServer,
    saveNodeState,
    expandedNodes
  } = state;

  const saveChangesHandler = async () => {
    try {
      updateState<State>({saveNodeState: {isLoading: true, isError: saveNodeState.isError}}, state, setState);
      const res = await BacktestServices.putAssignment(user.id ?? '', selectedNodes);
      updateState<State>({selectedNodesServer: res.data, saveNodeState: {isLoading: false, isError: false}}, state, setState);
      dispatch(updateSnackbar({ severity: 'success', message: 'Your selection was successfully saved' }));
    } catch (e) {
      updateState<State>({saveNodeState: {isLoading: false, isError: true}}, state, setState);
      throw e;
    }
  };

  const expandSelectedNodes = async () => {
    const nodesToExpand: Record<string, undefined> = {};
    (await getState()).selectedNodes.map((path) => {
      const pathItems = path.split('/');
      for (let i = 1; i < 5; i++) {
        const validPath: string[] = [];
        for (let j = 0; j < i; j++) {
          validPath.push(pathItems[j]);
        }
        nodesToExpand[validPath.join('/')] = undefined;
      }
      return undefined;
    });
    updateState<State>({expandedNodes: Object.keys(nodesToExpand)}, state, setState);
  };

  const descElement = (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col text-md'}>
        {
          !loadSelectedState.isError && !loadSelectedState.isLoading &&
          <ButtonXd state={saveNodeState}
                    icon={<Save />}
                    onClick={saveChangesHandler}
                    growOnMobile
                    disabled={compareUnorderedList(selectedNodes, selectedNodesServer)}>
            Save
          </ButtonXd>
        }
      </div>
      <p>Right-click on an entry to open it in a new tab.</p>
    </div>
  );

  useEffect(() => {
    (async () => {
      try {
        updateState<State>({loadSelectedState: {isLoading: true, isError: saveNodeState.isError}}, state, setState);
        const list = await BacktestServices.getAssignment(user.id ?? '');
        updateState<State>({ selectedNodesServer: list.data, selectedNodes: list.data, loadSelectedState: {isLoading: false, isError: false}}, state, setState);
      } catch (e) {
        updateState<State>({loadSelectedState: {isLoading: false, isError: true}}, state, setState);
      }

      try {
        updateState<State>({ loadTreeState: { isLoading: true, isError: loadTreeState?.isError } }, state, setState);
        const tree = await BacktestServices.getTree();
        updateState({ tree, loadTreeState: { isLoading: false, isError: false } }, state, setState);
      } catch (e) {
        updateState({ loadTreeState: { isLoading: false, isError: true } }, state, setState);
      }

      try {
        const backtestPaths = await BacktestServices.getAllBacktestPaths();
        updateState<State>({ allBacktests: backtestPaths.data }, state, setState);
      } catch (e) {
        // Ignore if error, it is not critical
      }

      await expandSelectedNodes();
    })();
  }, []);

  return (<Page title={'Backtest Access'}>
      <PageModule>
        <PageModuleTitle title={`Backtests assigned to ${user.firstName} ${user.lastName}`} />
        <PageModuleForm desc={descElement} wider>
          <StatefulApiResponseContainer state={loadTreeState}>
            <div className={'flex flex-col border rounded-lg p-2'}>
              {Object.keys(tree ?? {}).length === 0 && <DataNotAvailable/>}
              {Object.keys(tree ?? {}).map((e) => <BacktestTreeButton selectable={true}
                                                                      key={e} path={[e]}
                                                                      allBacktests={allBacktests}
                                                                      treeChildren={tree?.[e] ?? {}}
                                                                      selectedNodes={selectedNodes}
                                                                      onClick={(path) => {
                                                                        const selectedNodesCopy = [...selectedNodes];
                                                                        const pathIndex = selectedNodesCopy.indexOf(path);
                                                                        if (pathIndex < 0) {
                                                                          selectedNodesCopy.push(path);
                                                                        } else {
                                                                          selectedNodesCopy.splice(pathIndex, 1);
                                                                        }
                                                                        updateState<State>({ selectedNodes: selectedNodesCopy }, state, setState);
                                                                      }}
                                                                      expandedNodes={expandedNodes}
                                                                      onExpanded={(path) => {
                                                                        let expandedNodesCopy = [...expandedNodes];
                                                                        const pathIndex = expandedNodesCopy.indexOf(path);
                                                                        if (pathIndex < 0) {
                                                                          expandedNodesCopy.push(path);
                                                                        } else {
                                                                          expandedNodesCopy = expandedNodesCopy.filter((e) => !e.startsWith(path));
                                                                        }
                                                                        updateState<State>({ expandedNodes: expandedNodesCopy }, state, setState);
                                                                      }} />)}
            </div>
          </StatefulApiResponseContainer>
        </PageModuleForm>
      </PageModule>
    </Page>);
}