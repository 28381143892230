import React from 'react';

interface BorderedButtonProps {
  children?: JSX.Element;
  className?: string;
  iconOnRight?: boolean;
  label: string;
  onClick?: () => void;
}

export default function BorderedButton({
  children,
  className,
  label,
  onClick,
  iconOnRight,
}: BorderedButtonProps) {
  const iconElement = (
    <span className={'w-4 h-4'}>{children}</span>
  );

  return (
    <button
      className={`text-sm uppercase font-bold rounded-xl px-4 py-2 border border-gray-200 hover:bg-gray-50 flex gap-1 items-center text-gray-700 ${className}`}
      onClick={() => {
        onClick?.();
      }}
    >
      {children != null && !iconOnRight && iconElement}
      <span className={'-mb-0.5'}>{label}</span>
      {children != null && iconOnRight && iconElement}
    </button>
  );
}
