import React from 'react';
import { useAppSelector } from '../../hooks/hooks';

interface ActionButtonWrapperProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  widerBox?: boolean;
  borderlessContent?: boolean;
  type?: 'gallery' | 'list';
}

export default function ResponsiveGridWrapper({
  children,
  className,
  widerBox,
  borderlessContent,
  type,
}: ActionButtonWrapperProps) {
  const sidebarIsExpanded = useAppSelector(
    (state) => state.dashboard.sidebar.isExpanded
  );

  const stdExp = 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
  const stdCol =
    'md:grid-cols-3 lg:grid-cols-4';
  const widerExp = 'lg:grid-cols-2 xl:grid-cols-3';
  const widerCol = 'md:grid-cols-2 lg:grid-cols-3';

  const galleryWrapper = (
    <div
      className={
        `grid grid-cols-1 align-start auto-cols-fr ${className ?? ''} ` +
        `${borderlessContent ? 'gap-2 -mx-2' : 'gap-4'} ` +
        `${!widerBox && sidebarIsExpanded && stdExp} ` +
        `${!widerBox && !sidebarIsExpanded && stdCol} ` +
        `${widerBox && sidebarIsExpanded && widerExp} ` +
        `${widerBox && !sidebarIsExpanded && widerCol} `
      }
    >
      {children}
    </div>
  );

  const listWrapper = (
    <div className={'flex flex-col gap-2'}>
      {children}
    </div>
  );

  return (
    <>
      {
        !type && galleryWrapper
      }
      {
        type === 'gallery' && galleryWrapper
      }
      {
        type === 'list' && listWrapper
      }
    </>
  );
}
