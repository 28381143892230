import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/hooks';
import { addBreadcrumbTextReplacement } from '../../../store/appSlice';
import { Outlet } from 'react-router-dom';

export default function ArticlesOutlet() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement(['articles', 'Articles']));
  }, [dispatch]);

  return <Outlet />;
}
