import React from 'react';
import { addWbrTagsAfterUnderscore } from '../../utils/utils';

export interface ActionButtonProps {
  label: string;
  sublabel?: string;
  onClick?: () => void;
  children?: JSX.Element;
  href?: string;
  disabled?: boolean;
  type?: 'gallery' | 'list';
}

export default function ActionButton({
  label,
  onClick,
  children,
  href,
  disabled,
  sublabel,
  type,
}: ActionButtonProps) {
  const maxLabelLength = 30;

  const hoverActions = (!disabled
    ? 'hover:text-teal-1 hover:bg-teal-1 hover:bg-opacity-5 cursor-pointer'
    : 'opacity-50 cursor-not-allowed');

  const formattedLabel = (
    <>
      {!label.indexOf('_') && label}
      {label.indexOf('_') && addWbrTagsAfterUnderscore(type === 'gallery' ? label.slice(0, maxLabelLength) : label)}
    </>
  );

  const galleryButton = (
    <div
      title={label}
      className={
        `border rounded-xl relative text-gray-700 aspect-[3] md:aspect-[5/3] overflow-hidden ${hoverActions}`
      }
      onClick={() => {
        if (onClick != null && !disabled) {
          onClick();
        } else if (href) {
          window.open(href, '_blank');
        }
      }}
    >
      <div
        className={
          'font-normal absolute left-4 overflow-hidden flex flex-col gap-0 mr-14 md:mr-4 mb-0 bottom-4'
        }
      >
        {sublabel && <p className={'text-xs uppercase font-[600]'}>{sublabel}</p>}
        <p
          className={'text-2xl text-left'}
        >
          {formattedLabel}
          {label.length > maxLabelLength && '...'}
        </p>
      </div>
      <div className={'absolute top-4 right-4 w-8 h-8'}>{children}</div>
    </div>
  );

  const listButton = (
    <div title={label} className={`border rounded-xl relative text-gray-700 p-4 flex gap-4 text-lg items-start overflow-hidden ${hoverActions}`}>
      <span className={'w-6 h-6 flex-shrink-0'}>
        {children}
      </span>
      <div className={'flex flex-col gap-0'}>
        {
          sublabel && <p className={'text-xs uppercase font-[600]'}>{sublabel}</p>
        }
        <p className={'font-normal'}>{formattedLabel}</p>
      </div>
    </div>
  );

  return (
    <>
      {
        !type && galleryButton
      }
      {
        type === 'gallery' && galleryButton
      }
      {
        type === 'list' && listButton
      }
    </>
  );
}
