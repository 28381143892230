import React from 'react';

interface widthWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export default function WidthWrapper({children, className}: widthWrapperProps) {
  const classNames = ['px-4 sm:px-8'];

  if (className) {
    classNames.push(className);
  }

  return (<div className={classNames.join(' ')} style={{margin: 'auto', maxWidth: '1000px'}}>
    {children}
  </div>);
}