import React, {useEffect} from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import { addBreadcrumbTextReplacement } from '../../store/appSlice';
import { useLocation } from 'react-router-dom';
import PageFooter from './PageFooter';

interface pageProps {
  children: React.ReactNode;
  title?: string;
}

export default function Page({children, title}: pageProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (title) {
      dispatch(addBreadcrumbTextReplacement([location.pathname.split('/').pop(), title]));
      // document.title = title += ` • ${appName}`;
      document.title += title;
    }
  }, [title]);

  return (<div className={'xd-page flex flex-col w-full min-h-[calc(100vh-6rem-2px)]'}>
    {children}
    <PageFooter/>
  </div>);
}