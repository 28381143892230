import { createSlice } from '@reduxjs/toolkit';
import { type ViewsEnumDeprecate } from '../enums/viewsEnumDeprecate';
import { type ActionsEnumDeprecate } from '../enums/actionsEnumDeprecate';
import type BreadcrumbModel from '../models/BreadcrumbModel';
import { type AlertColor } from '@mui/material';

interface AppSliceState {
  appName: string;
  activeView: {
    type?: ViewsEnumDeprecate;
  };
  activeAction: {
    type?: ActionsEnumDeprecate;
  };
  breadcrumbs: BreadcrumbModel[];
  breadcrumbReplacementTextDict: Record<string, string>;
  breadcrumbReplacementTextDictTimestamp: number;
  dim: {
    h: number;
  };
  sidebar: {
    isExpanded: boolean;
    dim: {
      w: number;
    };
  };
  snackbar: {
    isVisible: boolean;
    message?: string;
    autoHideDuration: number;
    severity: AlertColor;
  };
  title: string;
  processLoading: number;
}

const initialState: AppSliceState = {
  appName: 'neXDos',
  activeView: {
    type: undefined,
  },
  activeAction: {
    type: undefined,
  },
  breadcrumbs: [],
  breadcrumbReplacementTextDict: {},
  breadcrumbReplacementTextDictTimestamp: 0,
  dim: {
    h: 0,
  },
  sidebar: {
    isExpanded: window.localStorage.getItem('sidebar-toggle')
      ? window.localStorage.getItem('sidebar-toggle') === 'true'
      : true,
    dim: {
      w: 0,
    },
  },
  snackbar: {
    isVisible: false,
    message: undefined,
    autoHideDuration: 7000,
    severity: 'info'
  },
  title: '',
  processLoading: 0,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateActiveView: (state, action) => {
      state.activeView.type = action.payload as ViewsEnumDeprecate | undefined;
    },
    updateActiveAction: (state, action) => {
      state.activeAction.type = action.payload as
        | ActionsEnumDeprecate
        | undefined;
    },
    updateDashboardH: (state, action) => {
      state.dim.h = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebar.isExpanded = !state.sidebar.isExpanded;
      window.localStorage.setItem(
        'sidebar-toggle',
        state.sidebar.isExpanded.toString()
      );
    },
    updatePageTitle: (state, action) => {
      // TO DEPRECATE SINCE FUTURE PAGE RENAME WILL OCCUR VIA PageModule
      if (action.payload) {
        state.title = (action.payload as string) + ' - ' + state.appName;
      } else {
        state.title = state.appName;
      }
      document.title = state.title;
    },
    addBreadcrumbTextReplacement: (state, action) => {
      const pair = action.payload as [string, string];
      state.breadcrumbReplacementTextDict[pair[0]] = pair[1];
      state.breadcrumbReplacementTextDictTimestamp = new Date().getTime();
    },
    purgeBreadcrumbTextReplacementDictForOutlets: () => {
      // Might be redundant
      // state.breadcrumbReplacementTextDict = {};
    },
    updateProcessLoading: (state, action) => {
      state.processLoading += action.payload ? 1 : -1;
    },
    updateSnackbar: (state, action) => {
      state.snackbar = { ...state.snackbar, ...action.payload };
      state.snackbar.isVisible = !!action.payload.message;
    },
  },
});

export const {
  updateActiveView,
  updateActiveAction,
  updateDashboardH,
  toggleSidebar,
  updatePageTitle,
  addBreadcrumbTextReplacement,
  purgeBreadcrumbTextReplacementDictForOutlets,
  updateProcessLoading,
  updateSnackbar,
} = appSlice.actions;
export default appSlice.reducer;
