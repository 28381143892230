import type UserAccountModel from '../models/UserAccountModel';
import ApiService from './apiService';

export default class UserManagementService extends ApiService {
  static async createAccount(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    role: string,
    twoFaRequired: boolean
  ) {
    return (
      await super.post<UserAccountModel>('accounts', undefined, {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        role,
        twoFaRequired,
      })
    );
  }

  static async getAccountList(page: number, pageSize: number, searchTerm: string) {
    return (
      await super.get<UserAccountModel[]>('accounts', undefined, {
        page: page.toString(),
        pageSize: pageSize.toString(),
        searchTerm
      })
    ).getData();
  }

  static async getAccount(email: string) {
    return (await super.get<UserAccountModel>('accounts', 'detail', { email }))
      .getData();
  }

  static async updateAccount(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    twoFaRequired: boolean
  ) {
    return (
      await super.put<UserAccountModel>('accounts', undefined, {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        role,
        twoFaRequired,
      })
    ).getData();
  }

  static async deleteAccount(email: string) {
    return (await super.delete('accounts', `?email=${email}`)).getData();
  }
}
