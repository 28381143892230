import React from 'react';
import type { StatefulApiResponseContainerModel } from '../../models/ApiReqStateModel';
import StatefulApiResponseContainer from '../StatefulApiResponseContainer';
import { Alert } from '@mui/material';

interface ContentPageWrapperProps
  extends Partial<StatefulApiResponseContainerModel> {
  title?: string;
  subtitle?: string;
  children: JSX.Element | JSX.Element[];
  limitWidth?: boolean;
}

export default function ContentPageWrapper({
  title,
  subtitle,
  children,
  limitWidth,
  state,
  errorMessage,
  severity,
}: ContentPageWrapperProps) {
  const paddingConfig = 'p-4 md:px-7';
  const styleConfig = { maxWidth: limitWidth ?? limitWidth === undefined ? '1000px' : 'auto' };

  const paddedAlert = (
    <div className={paddingConfig}>
      <Alert
        severity={severity ?? 'error'}
        style={styleConfig}
        className={'m-auto'}
      >
        {errorMessage ?? 'Failed to load'}
      </Alert>
    </div>
  );

  return (
    <>
      <StatefulApiResponseContainer
        state={state ?? { isError: false, isLoading: false }}
        errorMessage={paddedAlert}
        severity={severity}
      >
        <div className={paddingConfig}>
          <div className={'flex flex-col gap-6 m-auto'} style={styleConfig}>
            {(title ?? subtitle) && (
              <div className={'flex flex-col'}>
                {subtitle && <p className={'text-sm uppercase'}>{subtitle}</p>}
                {title && <h1 className={'text-3xl text-teal-4'}>{title}</h1>}
              </div>
            )}
            <div>{children}</div>
          </div>
        </div>
      </StatefulApiResponseContainer>
    </>
  );
}
