import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/hooks';

interface mobileMenuProps {
  isMenuExpanded: boolean;
  onClose: () => void;
  headerDropdown?: JSX.Element;
  mobileNavItems?: JSX.Element;
}

interface State {
  menuContentScrollBehavior?: 'auto' | 'hidden';
}

export default function MobileMenu({
  isMenuExpanded,
  onClose,
  headerDropdown,
  mobileNavItems,
}: mobileMenuProps) {
  const { dashboard } = useAppSelector((state) => state);

  const [state, setState] = useState<State>({});
  const { menuContentScrollBehavior } = state;
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };

  useEffect(() => {
    if (isMenuExpanded) {
      updateState({ menuContentScrollBehavior: 'hidden' });
      setTimeout(() => {
        updateState({ menuContentScrollBehavior: 'auto' });
      }, 300);
    } else {
      updateState({ menuContentScrollBehavior: 'hidden' });
    }
  }, [isMenuExpanded]);

  return (
    <>
      <div
        className={
          'lg:hidden bg-teal-2 z-50 fixed flex transition-all duration-300 text-white overflow-hidden w-[17rem]'
        }
        style={{
          height: `${dashboard.dim.h}px`,
          left: isMenuExpanded ? 0 : '-17rem',
          top: `${window.innerHeight - dashboard.dim.h}px`,
          boxShadow: isMenuExpanded ? 'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.04) 0px 6px 10px 0px, rgba(0, 0, 0, 0.03) 0px 1px 18px 0px' : '',
        }}
        onClick={onClose}
      >
        <div
          className={
            'flex flex-col w-full max-h-full py-8 px-2 text-2xl overflow-y-auto overflow-x-hidden'
          }
          style={{
            overflowY: menuContentScrollBehavior,
          }}
        >
          <div className={'text-lg flex flex-col gap-4 justify-center mx-3 mb-4'}>{headerDropdown}</div>
          <div className={'h-4'}/>
          {mobileNavItems}
        </div>
      </div>
      {
        isMenuExpanded &&
        <div className={'fixed w-[100vw] h-[100vh] bg-white top-0 left-0 z-[49] fade-in-80'}
             onClick={onClose}></div>
      }
    </>
  );
}
