import React from 'react';
import Header from '../components/dashboardHeader/Header';
import {Link} from 'react-router-dom';
import {PageRoutes} from '../enums/enums';
import {ArrowForward, Login} from '@mui/icons-material';
import PaddedButton from '../components/general/PaddedButton';
import {useAppSelector} from '../hooks/hooks';
import LogoutElement from '../components/dashboardHeader/LogoutElement';
import Page from '../components/layout/Page';
import PageModule from '../components/layout/PageModule';

export default function PageNotFound() {
  const { user } = useAppSelector((state) => state.authentication);

  const headerFloatRightItems = (
    <div className={'flex gap-4'}>
      {
        !user &&
        <Link to={`/${PageRoutes.LOGIN}`}>
          <div
            className={
              'flex gap-1 items-center text-white opacity-80 md:text-inherit md:opacity-100'
            }
          >
            <Login />
            <span>Login</span>
          </div>
        </Link>
      }
      {
        user &&
        <LogoutElement/>
      }
    </div>
  );

  return (
    <>
      <Header headerFloatRightItems={headerFloatRightItems}/>
      <div>
        <div className={'h-[calc(2.5rem+1px)]'}></div>
        <Page>
          <PageModule className={'flex flex-col justify-center h-[70vh]'}>
            <div className={'flex flex-col gap-4 text-center'}>
              <div>
                <h2>The requested page could not be found</h2>
              </div>
              <div>
                <Link to={'/'} replace>
                  <PaddedButton label={'Go to homepage'} icon={<ArrowForward/>} trailingIcon/>
                </Link>
              </div>
            </div>
          </PageModule>
        </Page>
      </div>
    </>
  );
}
