import React from 'react';
import Sidebar from '../components/dashboardSidebar/Sidebar';
import DashboardOutlet from './DashboardOutlet';
import Header from '../components/dashboardHeader/Header';
import SidebarContents from '../components/dashboardSidebar/SidebarContents';
import LogoutElement from '../components/dashboardHeader/LogoutElement';

export default function Dashboard() {
  return (
    <>
      <Header
        mobileNavItems={<SidebarContents sidebarIsExpanded={true} />}
        headerFloatRightItems={<LogoutElement />}
      />
      <div className={'flex'}>
        <Sidebar />
        <DashboardOutlet />
      </div>
    </>
  );
}
