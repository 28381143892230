import React, { useEffect, useState } from 'react';
import {
  type CompanyListResponse,
  type CompanyMeta,
} from '../../models/SingleStockReportModel';
import SingleStockServices from '../../services/singleStockServices';
import CustomTextField from '../../components/CustomTextField';
import GenericPaginator from '../../components/GenericPaginator';
import { Link, useSearchParams } from 'react-router-dom';
import ResponsiveGridWrapper from '../../components/dashboardContent/ResponsiveGridWrapper';
import CompanyInfoDetail from '../../components/CompanyInfoDetail';
import { Factory, Key, PieChart, Place, Storefront } from '@mui/icons-material';
import type { ApiReqStateModel } from '../../models/ApiReqStateModel';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import { Alert } from '@mui/material';
import StatefulApiResponseContainer from '../../components/StatefulApiResponseContainer';

interface State {
  response?: CompanyListResponse;
  searchReqState: ApiReqStateModel;
}

export default function CompanyIndex() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<State>({searchReqState: {isLoading: true, isError: false}});
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { response, searchReqState } = state;

  const fetchCompanies = (pg: number, search: string) => {
    updateState({searchReqState: {...searchReqState, isLoading: true}});
    SingleStockServices.getAllStocks(pg, search).then((res) => {
      if (
        res.meta.pagination.pages > 0 &&
        res.meta.pagination.page > res.meta.pagination.pages
      ) {
        // If page exceeds all available pages, bring user to first page
        fetchCompanies(1, search);
        return;
      }
      updateState({ response: res, searchReqState: { isLoading: false, isError: false } });
    }).catch(() => {
      updateState({searchReqState: {isError: true, isLoading: false}});
    });
  };

  const searchBoxHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const params = { ...Object.fromEntries([...searchParams]) };
    if (e.target.value !== '') {
      params.search = e.target.value;
    } else {
      delete params.search;
    }
    setSearchParams(params, { replace: true });
  };

  useEffect(() => {
    let cPage = Number.parseInt(searchParams.get('page') ?? '1');
    if (Number.isNaN(cPage)) {
      cPage = 1;
    }
    fetchCompanies(cPage, searchParams.get('search') ?? '');
  }, [searchParams]);

  return (
    <Page>
      <PageModule>
        <h2>Companies</h2>
        <div className={'flex gap-8 flex-wrap'}>
          <div className={'grow-[1000]'}>
            <CustomTextField
              className={'sm:w-full'}
              label={'Search'}
              value={searchParams.get('search') ?? ''}
              onChange={(e) => {
                searchBoxHandler(e);
              }}
              helperText={
                'Filter companies based on their company name, ticker, GVKEY,' +
                ' industry, sector, or exchange name'
              }
            />
          </div>
          <div className={'flex-grow grow-1'}>
            <GenericPaginator
              disabled={searchReqState.isLoading}
              pagination={response?.meta.pagination}
              retrievePageCallback={(pg) => {
                setSearchParams({
                  ...Object.fromEntries([...searchParams]),
                  page: pg.toString(),
                });
              }}
            />
          </div>
        </div>
        {response?.result.length === 0 && (
          <Alert severity={'info'}>There was no company found matching the requested criteria</Alert>
        )}
        <StatefulApiResponseContainer state={searchReqState}>
          <ResponsiveGridWrapper widerBox>
            {response?.result?.map((e, ix) => (
              <CompanyPreviewButton key={ix} attr={e} />
            ))}
          </ResponsiveGridWrapper>
        </StatefulApiResponseContainer>
        <GenericPaginator
          disabled={searchReqState.isLoading}
          pagination={response?.meta.pagination}
          retrievePageCallback={(pg) => {
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              page: pg.toString(),
            });
          }}
          className={'w-full'}
        />
      </PageModule>
    </Page>
  );
}

interface CompanyPreviewButtonProps {
  attr: CompanyMeta;
}

function CompanyPreviewButton({attr}: CompanyPreviewButtonProps) {
  return (
    <Link to={attr.tickersymbol ?? `${attr.companyid}-${attr.tradingitemid}-${attr.gvkey}`}>
      <div
        className={'flex flex-col gap-2 p-4 border rounded-lg h-full font-normal hover:bg-teal-1 hover:bg-opacity-5'}>
        <div className={'flex flex-col'}>
          <p className={'text-sm font-bold text-gray-600'}>
            {attr.tickersymbol}
          </p>
          <h3>{attr.companyname}</h3>
        </div>
        <div className={'flex flex-wrap gap-2 gap-x-4 text-gray-600 text-sm'}>
          <CompanyInfoDetail desc={attr.gvkey} icon={<Key/>}/>
          <CompanyInfoDetail desc={attr.loc} icon={<Place/>}/>
          <CompanyInfoDetail desc={attr.gsector} icon={<PieChart/>}/>
          <CompanyInfoDetail desc={attr.gind} icon={<Factory/>}/>
          <CompanyInfoDetail desc={attr.exchangename} icon={<Storefront/>}/>
        </div>
      </div>
    </Link>);
}
