import React, { useEffect, useState } from 'react';
import ContentPageWrapper from '../../components/dashboardContent/ContentPageWrapper';
import UserManagementService from '../../services/userManagementService';
import {
  DataGrid,
  type GridColDef,
} from '@mui/x-data-grid';
import { PageRoutes } from '../../enums/enums';
import {Link, useNavigate} from 'react-router-dom';
import type UserAccountModel from '../../models/UserAccountModel';
import { useAppDispatch } from '../../hooks/hooks';
import {updateUserObject} from '../../store/userSlice';
import CustomTextField from '../../components/CustomTextField';
import PaddedButton from '../../components/general/PaddedButton';
import {Add} from '@mui/icons-material';

const columns: GridColDef[] = [
  { field: 'first_name', headerName: 'First name', flex: 1,
    renderHeader: () => (<strong className={'text-base'}>First name</strong>),
    renderCell: (p) => (<p className={'text-base'}>{p.value}</p>),
  },
  { field: 'last_name', headerName: 'Last name', flex: 1,
    renderHeader: () => (<strong className={'text-base'}>Last name</strong>),
    renderCell: (p) => (<p className={'text-base'}>{p.value}</p>),
  },
  { field: 'email', headerName: 'Email', flex: 1,
    renderHeader: () => (<strong className={'text-base'}>Email</strong>),
    renderCell: (p) => (<p className={'text-base'}>{p.value}</p>),
  },
  { field: 'role', headerName: 'Role', flex: 1,
    renderHeader: () => (<strong className={'text-base'}>Role</strong>),
    renderCell: (p) => (<p className={'text-base'}>{p.value}</p>),
  },
];

export default function UsersOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserAccountModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');

  const loadAccounts = async () => {
    const users = await UserManagementService.getAccountList(page, pageSize, searchTerm);
    setUsers(users);
  };

  useEffect(() => {
    loadAccounts().then();
  }, [page, pageSize, searchTerm]);

  return (
    <ContentPageWrapper>
      <div className={'flex flex-col gap-8 max-w-[1000px] m-auto'}>
        <h2>Overview</h2>
        <div className={'flex gap-8 gap-y-4 flex-wrap'}>
          <div className={'flex-grow'}>
            <CustomTextField label="Search" value={searchTerm}
                             onChange={(e) => {setSearchTerm(e.target.value);}}/>
          </div>
          <Link to={`/${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.USERS_OUTLET}/${PageRoutes.USERS_NEW}`}
                className={'w-full sm:w-auto'}>
            <PaddedButton label={'Create User'} icon={<Add/>} growOnMobile/>
          </Link>
        </div>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[12]}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            setPage(page);
          }}
          onRowClick={(params, e) => {
            dispatch(updateUserObject({
              email: params.row.email,
              firstName: params.row.first_name,
              lastName: params.row.last_name,
              id: params.row.id,
            }));
            e.stopPropagation();
            navigate(PageRoutes.USERS_USER_HUB_OUTLET);
          }}
          autoHeight disableSelectionOnClick
          sx={{
            '&.MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </div>
    </ContentPageWrapper>
  );
}
