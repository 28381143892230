import { useState } from 'react';

export function updateState<Type>(obj: Partial<Type>, state: Type, setState: (st: (s: Type) => Type & Partial<Type>) => void) {
  setState((state) => ({...state, ...obj}));
}

export function useStateExtended<Type>(initialState: Type) {
  const [state, setState] = useState<Type>(initialState);
  const getLatestState = async () => {
    // Returns the latest state when accessed via a callback
    return await new Promise<Type>((resolve) => {
      setState((s) => {
        resolve(s);
        return s;
      });
    });
  };

  return [state, setState, getLatestState] as const;
}

export function disableScroll(isTrue: boolean): void {
  const scrollbarW = 0;
  if (isTrue) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.paddingRight = `${scrollbarW}px`;
  } else {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY ?? '0') * -1);
    document.body.style.paddingRight = '0px';
  }
}

export function debounce(fn: () => void, dur?: number): () => void {
  const time = dur ?? 0; // 10 by default if no param
  let timer: NodeJS.Timeout;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(fn, time);
  };
}

export function formatDate(
  time: number | string | Date,
  options?: Intl.DateTimeFormatOptions
): string {
  let dateObject: Date;

  if (typeof time === 'number' || typeof time === 'string') {
    dateObject = new Date(time);
  } else {
    dateObject = time;
  }

  return dateObject.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'shortGeneric',
    ...options,
  });
}

export function parseJwt (token: string) {
  if (!token || token === '') return undefined;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}