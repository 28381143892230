import React from 'react';

interface statefulModuleDisablerProps {
  children: JSX.Element;
  disabled: boolean;
}

export default function StatefulModuleDisabler({
  children,
  disabled,
}: statefulModuleDisablerProps) {
  const classNames = ['relative'];

  if (disabled) {
    classNames.push('pointer-events-none');
  }

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
}
