import axios, { type AxiosResponse } from 'axios';
import type ErrorResponse from '../models/ErrorResponse';

export class ApiResponse<T> {

  /**
   *
   */
  constructor(public response: AxiosResponse<T> | ErrorResponse) {
  }

  public hasError() {
    return Object.keys(this.response).includes('error') || Object.keys((this.response as AxiosResponse<ErrorResponse>).data).includes('error');
  }

  public getError() {
    return (this.response as AxiosResponse<ErrorResponse>).data;
  }

  public getData() {
    return (this.response as AxiosResponse<T>).data;
  }
}

/**
 * @deprecated This class is deprecated. Use `APIService` class instead.
 */
export default abstract class BaseApiService {
  private static readonly baseUri = '/api';

  protected static async get<T extends object>(uri: string, params: Record<string, any> = {}, options = {}) {
    let paramString = '';
    if (Object.keys(params).length > 0) {
      paramString += `?${new URLSearchParams(params).toString()}`;
    }
    return new ApiResponse<T>(await axios.get<T>(`${this.baseUri}/${uri}${paramString}`, options));
  }

  protected static async put<T>(uri: string, param: any) {
    return new ApiResponse<T>(await axios.put<T>(`${this.baseUri}/${uri}`, param));
  }

  protected static async post<T>(uri: string, param: any) {
    return new ApiResponse<T>(await axios.post<T>(`${this.baseUri}/${uri}`, param));
  }

  protected static async delete<T>(uri: string) {
    return new ApiResponse<T>(await axios.delete<T>(`${this.baseUri}/${uri}`));
  }
}
