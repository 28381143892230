import React, { useEffect } from 'react';
import { useAppDispatch } from '../hooks/hooks';
import { updateSnackbar } from '../store/appSlice';

interface Props {
  children: JSX.Element[];
}

export default function ErrorBoundary({children}: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const dispatchMessage = (e: PromiseRejectionEvent) => {
      dispatch(updateSnackbar({ severity: 'error', message: e.reason.message }));
    };
    window.addEventListener('unhandledrejection', dispatchMessage);

    return () => {
      window.removeEventListener('unhandledrejection', dispatchMessage);
    };
  }, [dispatch]);

  return (
    <>{children}</>
  );
}