import React from 'react';
import GenericTableColumn from './GenericTableColumn';
import GenericTableTile from './GenericTableTile';

interface TableProps {
    data: Record<string, number | string>;
    decimals: number;
}

/***
 * Generic Table without Index to create regular tables without the indexes.
 * @param data Table data provided in the form of {col1: value1, col2: value2}
 * @param decimals Integer value to determine the decimals of the number values
 */


export default function GenericTableWithoutIndex({ data, decimals }: TableProps) {
    const entries = Object.entries(data);

    if (entries.length === 0) {
        return <p>Data is not available</p>;
    }

    return (
        <table className={'table-auto w-full'}>
            <thead>
            <tr>
                {entries.map(([column], index) => (
                    <GenericTableColumn key={index} column={column} />
                ))}
            </tr>
            </thead>
            <tbody>
            <tr>
                {entries.map(([column, value], index) => (
                    <GenericTableTile key={index} column={column} value={value} decimals={decimals} />
                ))}
            </tr>
            </tbody>
        </table>
    );
}
