import React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { splitCamelCase } from '../../utils/utils';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

interface BreadcrumbsProps {
  isContentOverflowing: boolean;
}

export default function ManualBreadcrumbs({
  isContentOverflowing,
}: BreadcrumbsProps) {
  const trail = useAppSelector((state) => state.dashboard.breadcrumbs);

  return (
    <div
      className={
        'flex items-center gap-1 text-teal-3 px-4 py-3 sticky z-10 top-0 bg-white overflow-auto font-bold ' +
        `${isContentOverflowing ? 'border-b' : ''}`
      }
    >
      {trail?.map((x, ix) => (
        <span key={ix} className={'align-middle flex-none'}>
          <button
            className={`uppercase inline text-sm cursor-pointer hover:text-teal ${
              ix + 1 === trail.length ? 'text-teal-ppt-1' : ''
            }`}
          >
            {splitCamelCase(x.label).join(' ')}
          </button>
          {ix + 1 !== trail.length && (
            <ChevronRightIcon
              className={'inline w-4 h-4 ml-1.5'}
              strokeWidth={2}
            />
          )}
        </span>
      ))}
    </div>
  );
}
