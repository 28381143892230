import React from 'react';
import SidebarHeader from './SidebarHeader';
import SidebarLinkButton from './SidebarLinkButton';
import { PageRoutes } from '../../enums/enums';
import {
  InboxStackIcon as InboxStackIconOutline,
  NewspaperIcon as NewspaperIconOutline,
  PresentationChartLineIcon as PresentationChartLineIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  // DocumentChartBarIcon as DocumentChartBarIconOutline,
  // SparklesIcon as SparklesIconOutline,
} from '@heroicons/react/24/outline';
import {
  InboxStackIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  // DocumentChartBarIcon,
  // SparklesIcon,
} from '@heroicons/react/24/solid';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';

interface sidebarContentProps {
  sidebarIsExpanded: boolean;
}

export default function SidebarContents({
  sidebarIsExpanded,
}: sidebarContentProps) {
  const path = useLocation().pathname.split('/');
  const { user } = useAppSelector((state) => state.authentication);

  return (
    <div className={'flex flex-col gap-2'}>
      <SidebarHeader show={sidebarIsExpanded}>View</SidebarHeader>
      <SidebarLinkButton
        to={PageRoutes.NEWS_OUTLET}
        label={'News'}
        isSelected={[...path][2] === PageRoutes.NEWS_OUTLET}
        icon={<NewspaperIconOutline />}
        iconSelected={<NewspaperIcon />}
      />
      <SidebarLinkButton
        to={PageRoutes.COMPANIES_OUTLET}
        label={'Stock'}
        isSelected={[...path][2] === PageRoutes.COMPANIES_OUTLET}
        icon={<PresentationChartLineIconOutline />}
        iconSelected={<PresentationChartLineIcon />}
      />
      {/* <SidebarLinkButton */}
      {/*  to={PageRoutes.BACKTEST_OUTLET} */}
      {/*  label={'Backtest'} */}
      {/*  isSelected={[...path][2] === PageRoutes.BACKTEST_OUTLET} */}
      {/*  icon={<DocumentChartBarIconOutline/>} */}
      {/*  iconSelected={<DocumentChartBarIcon/>} */}
      {/* /> */}
      {/* <SidebarLinkButton */}
      {/*  to={PageRoutes.FACTSHEET_OUTLET} */}
      {/*  label={'Factsheet'} */}
      {/*  isSelected={[...path][2] === PageRoutes.FACTSHEET_OUTLET} */}
      {/*  icon={<SparklesIconOutline/>} */}
      {/*  iconSelected={<SparklesIcon/>} */}
      {/* /> */}
      {user?.isAdmin && (
        <>
          {
            sidebarIsExpanded && <div className={'h-2'}/>
          }
          <SidebarHeader show={sidebarIsExpanded}>Admin Console</SidebarHeader>
          <SidebarLinkButton
            to={PageRoutes.PUBLISHING_OUTLET}
            label={'Publishing'}
            isSelected={[...path][2] === PageRoutes.PUBLISHING_OUTLET}
            icon={<InboxStackIconOutline />}
            iconSelected={<InboxStackIcon />}
          />
          <SidebarLinkButton
            to={PageRoutes.USERS_OUTLET}
            label={'User Management'}
            isSelected={[...path][2] === PageRoutes.USERS_OUTLET}
            icon={<UserGroupIconOutline />}
            iconSelected={<UserGroupIcon />}
          />
        </>
      )}
    </div>
  );
}
