import React from 'react';
import SidebarButton from './SidebarButton';
import { Link } from 'react-router-dom';

interface SidebarLinkProps {
  to: string;
  label: string;
  isSelected: boolean;
  icon?: JSX.Element;
  iconSelected?: JSX.Element;
}

export default function SidebarLinkButton({
  to,
  label,
  isSelected,
  icon,
  iconSelected,
}: SidebarLinkProps) {
  return (
    <Link to={to}>
      <SidebarButton
        label={label}
        isSelected={isSelected}
        icon={icon}
        iconSelected={iconSelected}
      />
    </Link>
  );
}
