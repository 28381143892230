import { TabPanel } from '@mui/lab';
import React from 'react';

interface tabPanelXdProps {
  children: React.ReactNode;
  value: string;
}

export default function TabPanelXd({children, value}: tabPanelXdProps) {
  return (
    <TabPanel value={value} sx={{padding: 0, marginTop: '0.5rem'}}>
      {children}
    </TabPanel>
  );
}