import React, { useEffect, useState } from 'react';
import PerformanceDatePicker from './PerformanceDatePicker';
import { type PerformanceTable } from '../models/BacktestModel';
import SingleStockServices from '../services/singleStockServices';
import { type ApiReqStateModel } from '../models/ApiReqStateModel';
import { updateState } from '../helpers/helper';

interface StockPerformance {
  companyId: string;
  tradingId: string;
  strategy: string;
  benchmark: string;
  perfTable: PerformanceTable | undefined;
  benchmarkDisplayLabel: string;
}

interface State {
  aPerfTable?: PerformanceTable;
  iPerfTable?: PerformanceTable;
  displayMessage?: string;
  reqState: ApiReqStateModel;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function StockPerformance({companyId, tradingId, strategy, benchmark, benchmarkDisplayLabel, perfTable}: StockPerformance) {
  const [state, setState] = useState<State>({
    aPerfTable: perfTable,
    iPerfTable: perfTable,
    reqState: { isLoading: false, isError: false },
  });
  const {displayMessage, aPerfTable, iPerfTable, reqState} = state;

  const refreshHandler = async (start: string, end: string) => {
    updateState<State>({reqState: { isLoading: true, isError: reqState.isError }}, state, setState);
    try {
      const res = await SingleStockServices.getStockPerformance(companyId, tradingId, start, end);
      if (res.message) {
        updateState({ aPerfTable: undefined, displayMessage: res.message, reqState: { isLoading: false, isError: false } }, state, setState);
      } else {
        updateState({ aPerfTable: res.performanceTable, displayMessage: undefined, reqState: { isLoading: false, isError: false } }, state, setState);
      }
    } catch (e) {
      updateState<State>({reqState: { isLoading: false, isError: true }}, state, setState);
    }
  };

  useEffect(() => {
    updateState({iPerfTable: perfTable, aPerfTable: perfTable}, state, setState);
  }, [perfTable]);

  return (
    <PerformanceDatePicker
      iPerfTable={iPerfTable ?? {}}
      perfTable={aPerfTable ?? {}}
      strategy={strategy}
      benchmark={benchmark}
      refreshCallback={refreshHandler}
      displayMsg={displayMessage}
      benchmarkLabel={benchmarkDisplayLabel}
      state={reqState}
    />
  );
}