import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import {
  addBreadcrumbTextReplacement,
  purgeBreadcrumbTextReplacementDictForOutlets,
} from '../../store/appSlice';
import { ViewsEnumDeprecate } from '../../enums/viewsEnumDeprecate';
import { Outlet } from 'react-router-dom';

export default function PublishingOutlet() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      addBreadcrumbTextReplacement([
        ViewsEnumDeprecate[ViewsEnumDeprecate.publishing],
        'Publishing',
      ])
    );
    return () => {
      dispatch(purgeBreadcrumbTextReplacementDictForOutlets());
    };
  }, [dispatch]);

  return <Outlet />;
}
