import type {
  CompanyInfo,
  CompanyListResponse,
  CompanyPerformanceResponse,
  StockReportModel,
} from '../models/SingleStockReportModel';
import ApiService from './apiService';

export default class SingleStockServices extends ApiService {
  static async getAllStocks(page: number, search: string) {
    return (
      await super.get<CompanyListResponse>('stock', 'listAll', { page, search })
    ).getData();
  }

  static async getStockReport(cid: number, tid: number) {
    return (
      await super.get<StockReportModel>('stock', undefined, { cid, tid })
    ).getData();
  }

  static async getStockPerformance(
    companyId: string,
    tradingId: string,
    start: string,
    end: string
  ) {
    return (
      await super.get<CompanyPerformanceResponse>('stock', 'performance', {
        companyId,
        tradingId,
        start,
        end,
      })
    ).getData();
  }

  static async getCompanyInfo(identifier: string) {
    return (
      await super.get<CompanyInfo>('stock', `/info/${identifier}`)
    ).getData();
  }
}
