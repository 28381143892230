import React from 'react';

interface TileProps {
    column: string;
    value: string | number;
    decimals: number;
}

/***
 * Generic Table Tile to be reused in indexes and non-indexes tables.
 * @param column Column name to be checked if it is a date column or a regular number column
 * @param value Value to be formatted inside the tile
 * @param decimals Integer value to determine the decimals of the number values
 */

export default function GenericTableTile({ column, value, decimals }: TileProps) {

    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    return (
        <td className={'border px-4 py-2 text-center text-teal-1'}>
            {/date/i.test(column) && typeof value === 'string' ? formatDate(value) :
                typeof value === 'number' ? value.toFixed(decimals) : String(value)}
        </td>
    );
}
