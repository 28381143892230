import React from 'react';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';

export default function PageFooter() {
  return (
    <div className={'flex-grow flex flex-col justify-end'}>
      <div className={'p-4 text-xs w-full'}>
        <div className={'flex flex-wrap gap-4 gap-y-2 justify-center md:justify-end'}>
          <Link to={'https://www.nexdos.de/impressum/'} target={'_blank'}>Impressum <OpenInNew/></Link>
          <Link to={'https://www.nexdos.de/privacy-policy/'} target={'_blank'}>Datenschutzerklärung <OpenInNew/></Link>
          <span>
            &copy; {new Date().getFullYear()} neXDos GmbH
          </span>
        </div>
      </div>
    </div>
  );
}