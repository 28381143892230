import React from 'react';

interface singleRowTableProps {
  children: React.ReactNode;
}

export default function SingleRowTable({children}: singleRowTableProps) {
  return (
    <div className={'flex flex-wrap gap-y-2 gap-[1px]'}>
      {children}
    </div>
  );
}