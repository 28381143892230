import React from 'react';
import { type Layout, type PlotData } from 'plotly.js';
import PlotWrapper from './PlotWrapper';
import PageModuleTitle from './layout/PageModuleTitle';

export interface GenericPlotProps {
  data: Array<Partial<PlotData>>;
  layout?: Partial<Layout>;
  modalTitle: string;
  excludeHeader?: boolean;
}

export default function GenericPlot({
                                      data,
                                      layout,
                                      modalTitle,
                                      excludeHeader,
                                    }: GenericPlotProps) {
  return (
    <div className='flex flex-col gap-6'>
      {!excludeHeader && <PageModuleTitle level={'h2'} title={modalTitle}/>}
      <PlotWrapper data={data} layout={layout} modalTitle={modalTitle} />
    </div>
  );
};