import React from 'react';
import type { StatefulApiResponseContainerModel } from '../models/ApiReqStateModel';
import LoadWheel from './general/LoadWheel';
import { Alert } from '@mui/material';

interface statefulContentContainerProps
  extends StatefulApiResponseContainerModel {
  children?: React.ReactNode;
}

/**
 * A placeholder module container to display error message if the API request
 * fails. In the future, should also include a load wheel. The container fills
 * the entire width of the parent container.
 * @param children
 * @param pageState
 * @param errorMessage
 * @constructor
 */
export default function StatefulApiResponseContainer({
  children,
  state,
  errorMessage,
  severity,
  displayWhen,
}: statefulContentContainerProps) {
  return (
    <div
      className={'w-full relative min-h-[3rem]'}
    >
      {state.isError && (
        <>
          {typeof errorMessage !== 'object' && (
            <Alert severity={severity ?? 'error'}>
              {errorMessage ?? 'Failed to load'}
            </Alert>
          )}
          {typeof errorMessage === 'object' && errorMessage}
        </>
      )}
      {!state.isError && (displayWhen === undefined || displayWhen) && (
        <div style={{ opacity: state.isLoading ? '0.2' : 1 }}>{children}</div>
      )}
      {state.isLoading && (
        <div
          className={
            'absolute h-full top-0 left-0 w-full flex flex-col cursor-wait'
          }
        >
          <div className={'flex flex-col h-full max-h-[50vh] justify-center'}>
            <LoadWheel />
          </div>
        </div>
      )}
    </div>
  );
}
