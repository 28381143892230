export enum PageRoutes {
  HOME = 'home',
  NEWS_OUTLET = 'news',
  NEWS_HOME = '',
  NEWS_ARTICLES_OUTLET = 'articles',
  NEWS_ARTICLES_HOME = '',
  LOGIN = 'login',
  DASHBOARD_HOME = '',
  DASHBOARD_OUTLET = 'app',
  PUBLISHING_OUTLET = 'publishing',
  PUBLISHING_HOME = '',
  PAGE_NOT_FOUND = '*',
  USERS_OUTLET = 'usrmgmt',
  USERS_HOME = '',
  COMPANIES_OUTLET = 'stock',
  COMPANIES_HOME = '',
  USERS_NEW = 'new',
  USERS_BACKTEST = 'backtest',
  USERS_EDIT = 'edit',
  USERS_DELETE = 'delete',
  USERS_OVERVIEW_INDEX = '',
  USERS_OVERVIEW_OUTLET = 'accounts',
  USERS_USER_HUB_OUTLET = 'dossier',
  USERS_USER_HUB_INDEX = '',
  BACKTEST_OUTLET = 'backtest',
  BACKTEST_HOME = '',
  FACTSHEET_OUTLET = 'factsheet',
  FACTSHEET_HOME = ''
}
