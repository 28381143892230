import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/hooks';
import {
  Factory,
  History,
  Key,
  PieChart,
  Place,
  PowerSettingsNew,
  Storefront,
  Web,
} from '@mui/icons-material';
import { type StockReportModel } from '../../models/SingleStockReportModel';
import CompanyInfoDetail from '../../components/CompanyInfoDetail';
import { type ApiReqStateModel } from '../../models/ApiReqStateModel';
import SingleStockServices from '../../services/singleStockServices';
import { updateState, useStateExtended } from '../../helpers/helper';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import {
  abbreviateNumber,
  colorCycler,
  replaceTechnicalTerms,
} from '../../utils/utils';
import StockPerformance from '../../components/StockPerformance';
import ReportPlotAccordion from '../../ReportPlotAccordion';
import PageModuleTitle from '../../components/layout/PageModuleTitle';

interface State {
  stockReport?: Partial<StockReportModel>;
  infoReqState: ApiReqStateModel;
  reportReqState: ApiReqStateModel;
}

export default function StockEntry() {
  const dispatch = useAppDispatch();

  const [state, setState, getState] = useStateExtended<State>({
    infoReqState: {
      isError: false,
      isLoading: true,
    },
    reportReqState: {
      isError: false,
      isLoading: false,
    },
  });
  const { stockReport, infoReqState, reportReqState } = state;

  const { identifier } = useParams();

  const infoNuggets = (
    <div className={'flex items-center justify-center'}>
      <div
        className={
          'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2 text-center'
        }
      >
        {Object.entries(stockReport?.infoNuggets ?? []).map((e, ix) => (
          <InfoNugget key={ix} ix={ix} label={e[0]} value={e[1]} />
        ))}
      </div>
    </div>
  );

  const companyInfoDetails = (
    <>
      <CompanyInfoDetail
        desc={stockReport?.companyInfo?.gvkey}
        icon={<Key />}
      />
      <CompanyInfoDetail
        desc={stockReport?.companyInfo?.gsector}
        icon={<PieChart />}
      />
      <CompanyInfoDetail
        desc={stockReport?.companyInfo?.gind}
        icon={<Factory />}
      />
      <CompanyInfoDetail
        desc={stockReport?.companyInfo?.companystatustypename}
        icon={<PowerSettingsNew />}
      />
      <CompanyInfoDetail
        desc={`Founded in ${stockReport?.companyInfo?.yearfounded}`}
        icon={<History />}
      />
      <CompanyInfoDetail
        desc={`${stockReport?.companyInfo?.city}, ${stockReport?.companyInfo?.country}`}
        icon={<Place />}
      />
      <CompanyInfoDetail
        desc={stockReport?.companyInfo?.exchangename}
        icon={<Storefront />}
      />
      <CompanyInfoDetail
        desc={stockReport?.companyInfo?.webpage}
        icon={<Web />}
        isHyperlink
      />
    </>
  );

  useEffect(() => {
    (async () => {
      updateState<State>(
        {
          infoReqState: { isLoading: true, isError: infoReqState.isError },
        },
        state,
        setState
      );

      let cid: number;
      let tid: number;

      try {
        const res = await SingleStockServices.getCompanyInfo(identifier ?? '');
        cid = res.companyid;
        tid = res.tradingitemid;
        updateState<State>(
          {
            stockReport: {
              ...(await getState()).stockReport,
              companyInfo: res,
            },
            infoReqState: { isLoading: false, isError: false },
          },
          state,
          setState
        );
      } catch (e) {
        updateState<State>(
          {
            infoReqState: { isLoading: false, isError: true },
          },
          state,
          setState
        );
        return;
      }

      updateState<State>(
        {
          reportReqState: { isLoading: true, isError: reportReqState.isError },
        },
        state,
        setState
      );

      try {
        const res = await SingleStockServices.getStockReport(cid, tid);
        updateState<State>(
          {
            stockReport: { ...(await getState()).stockReport, ...res },
            reportReqState: { isLoading: false, isError: false },
          },
          state,
          setState
        );
      } catch (e) {
        updateState<State>(
          {
            reportReqState: { isLoading: false, isError: true },
          },
          state,
          setState
        );
      }
    })();
  }, [dispatch, identifier]);

  return (
    <Page title={stockReport?.companyInfo?.companyname}>
      <PageModule
        state={infoReqState}
        errorMessage={'The requested company could not be found'}
      >
        <div className={'grid grid-cols-1 lg:grid-cols-2 gap-8'}>
          <div className={'flex flex-col gap-6'}>
            {stockReport?.companyInfo && (
              <>
                <div className={'flex flex-col'}>
                  <PageModuleTitle title={stockReport.companyInfo.companyname} subtitle={stockReport.companyInfo.tickersymbol} highlight/>
                </div>
                <div className={'flex flex-col gap-2'}>
                  {companyInfoDetails}
                </div>
              </>
            )}
          </div>
          {stockReport?.infoNuggets && infoNuggets}
          {stockReport?.companyInfo && (
            <p className={'col-span-1 lg:col-span-2'}>
              {stockReport.companyInfo.busdesc}
            </p>
          )}
        </div>
      </PageModule>
      {stockReport?.totalPerformance && (
        <PageModule
          state={reportReqState}
          background={<div className={'bg-slate-50'} />}
        >
          <StockPerformance
            companyId={stockReport?.companyInfo?.companyid.toString() ?? ''}
            tradingId={stockReport?.companyInfo?.tradingitemid.toString() ?? ''}
            strategy={'stock'}
            benchmark={'benchmark'}
            benchmarkDisplayLabel={'S&P 500 Value'}
            perfTable={stockReport.performanceTable}
          />
        </PageModule>
      )}
      {stockReport?.companyInfo && (
        <PageModule state={reportReqState}>
          {stockReport?.performanceTable && (
            <>
              <ReportPlotAccordion
                data={stockReport?.totalPerformance?.data}
                modalTitle={'Total Return'}
                layout={stockReport?.totalPerformance?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.volatility?.data}
                modalTitle={'Total Return and Volatility'}
                layout={stockReport?.volatility?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.yearlyPerformance?.data}
                modalTitle={'Annual Performance'}
                layout={stockReport?.yearlyPerformance?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.underwater?.data}
                modalTitle={'Drawdown'}
                layout={stockReport?.underwater?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.difference?.data}
                modalTitle={'Return Difference to Benchmark'}
                layout={stockReport?.difference?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.marketCapitalization?.data}
                modalTitle={'Market Capitalization'}
                layout={stockReport?.marketCapitalization?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.sharesOutstanding?.data}
                modalTitle={'Shares Outstanding'}
                layout={stockReport?.sharesOutstanding?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.dividendYield?.data}
                modalTitle={'Dividend Yield'}
                layout={stockReport?.dividendYield?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.dividendAmountPerShare?.data}
                modalTitle={'Dividend Amount per Share'}
                layout={stockReport?.dividendAmountPerShare?.layout}
              />
              <ReportPlotAccordion
                data={stockReport?.spread?.data}
                modalTitle={'Bid/Ask Spread'}
                layout={stockReport?.spread?.layout}
              />
            </>
          )}
        </PageModule>
      )}
    </Page>
  );
}

interface InfoNuggetProps {
  ix: number;
  label: string;
  value: number | string;
}

function InfoNugget({ label, value, ix }: InfoNuggetProps) {
  return (
    <div
      className={
        'flex flex-col gap-2 items-center justify-center rounded-xl p-6'
      }
      style={{ borderWidth: 3, borderColor: colorCycler(ix) }}
    >
      <p className={'uppercase text-sm m-0'}>{replaceTechnicalTerms(label)}</p>
      <h6
        className={'uppercase text-4xl m-0 -mt-1'}
        style={{ color: colorCycler(ix) }}
      >
        {abbreviateNumber(value)}
      </h6>
    </div>
  );
}
