import React, { useEffect, useState } from 'react';
import BorderedButton from '../../components/dashboardContent/BorderedButton';
import UserManagementService from '../../services/userManagementService';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { type Role } from '../../models/UserAccountModel';
import TwoFaQrCodeModal from './TwoFaQrCodeModal';
import CustomTextField from '../../components/CustomTextField';
import CustomSelectDropdown from '../../components/general/CustomSelectDropdown';
import PaddedButton from '../../components/general/PaddedButton';
import { ArrowForward } from '@mui/icons-material';
import { useAppDispatch } from '../../hooks/hooks';
import { addBreadcrumbTextReplacement, updateSnackbar } from '../../store/appSlice';
import { PageRoutes } from '../../enums/enums';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import PageModuleForm from '../../components/layout/PageModuleForm';

export default function CreateUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [role, setRole] = useState<Role>('user');
  const [twoFaUri, setTwoFaUri] = useState<string>();
  const [twoFaEnabled, setTwoFaEnabled] = useState(false);
  const [displayQrCode, setDisplayQrCode] = useState(false);

  const submitForm = async () => {
    if (!email || !password || !firstName || !lastName || !role) {
      dispatch(updateSnackbar({
        severity: 'error',
        message: 'Please ensure that all fields are filled before submitting.'
      }));
    } else {
      const response = await UserManagementService.createAccount(
        firstName,
        lastName,
        email,
        password,
        role,
        twoFaEnabled
      );
      
      if (response.hasError()) {
        dispatch(updateSnackbar({ severity: 'error', message: response.getError().error.message }));
      } else {
        const user = response.getData();
        if (user.twoFaUri !== undefined) {
          setDisplayQrCode(true);
          setTwoFaUri(user.twoFaUri);
        }
        navigate(`/${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.USERS_OUTLET}/${PageRoutes.USERS_OVERVIEW_OUTLET}`);
      }
    }
  };

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([PageRoutes.USERS_NEW, 'New user']));
  }, [dispatch]);

  return (<Page>
      <PageModule>
        <PageModuleTitle title={'Create a new user account'}/>
        <PageModuleForm title={'Account details'}>
          <CustomTextField
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <CustomTextField
            label="Password"
            type={'password'}
            placeholder={'Type in password'}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div className={'flex gap-4 flex-wrap'}>
            <CustomSelectDropdown label={'Role'} className={'capitalize'} value={role}
                                  onChange={(e) => {
                                    setRole(e.target.value as Role);
                                  }} growOnMobile>
              {['user', 'admin'].map((e) => <MenuItem key={e} value={e} className={'capitalize'}>{e}</MenuItem>)}
            </CustomSelectDropdown>
            <FormControlLabel
              value="start"
              control={<Checkbox
                checked={twoFaEnabled}
                onChange={(e) => {
                  setTwoFaEnabled(e.target.checked);
                }}
              />}
              label="Enable two-factor authentication"
              labelPlacement="end"
            />
          </div>
        </PageModuleForm>
        <PageModuleForm title={'Personal details'}>
          <div className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
            <CustomTextField
              label="First name"
              placeholder={'Type in first name'}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <CustomTextField
              label="Last name"
              placeholder={'Type in last name'}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
        </PageModuleForm>
        <PageModuleForm>
          <PaddedButton className={'my-6'} label={'Submit'} onClick={submitForm} icon={<ArrowForward/>} trailingIcon growOnMobile/>
        </PageModuleForm>
        {twoFaUri && (
          <PageModuleForm>
            <BorderedButton
              className={'my-6'}
              label={'Show QR-Code'}
              onClick={() => {
                setDisplayQrCode(true);
              }}
            />
          </PageModuleForm>)}
      </PageModule>
      <TwoFaQrCodeModal
        open={displayQrCode}
        uri={twoFaUri}
        setDisplayQrCode={setDisplayQrCode}
      />
    </Page>
  );
}
