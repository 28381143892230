import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import AuthenticationService from '../../services/authenticationService';
import { login } from '../../store/authenticationSlice';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import { type ApiReqStateModel } from '../../models/ApiReqStateModel';
import ButtonXd from '../../components/dashboardContent/ButtonXd';

interface UserLoginForm {
  email: string;
  password: string;
}

interface State {
  form?: Partial<UserLoginForm>;
  showPassword?: boolean;
  reqState: ApiReqStateModel;
}

export default function LoginForm() {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<State>({
    reqState: {isLoading: false, isError: false},
  });
  const updateState = (o: Partial<State>) => {
    setState((state) => ({ ...state, ...o }));
  };
  const { form, showPassword, reqState } = state;

  const updateForm = (o: Partial<UserLoginForm>) => {
    updateState({ ...state, form: { ...form, ...o } });
  };

  const executeLogin = async () => {
    if (form?.email && form?.password) {
      updateState({reqState: {isLoading: true, isError: reqState.isError}});
      try {
        const res = await AuthenticationService.login(form.email, form.password);

        // NOTE: On success, automatic page redirect is governed in NonRequireAuth.tsx, look for the use of Navigate
        // component. This is because we also log users out when the backend server is down, so we do not
        // redirect users anywhere from this point in the code.

        // Save password in case of 2FA
        dispatch(
          login({ ...res, email: form.email, password: form.password })
        );

        updateState({reqState: {isLoading: false, isError: false}});
      } catch (e: any) {
        updateState({reqState: {isLoading: false, isError: true}});
        if (e.code === 'ERR_NETWORK' || e.code === 'ERR_BAD_RESPONSE') throw e;
        e.message = 'Invalid email address or password. Please try again.';
        throw e;
      }
    }
  };

  const handleClickShowPassword = () => {
    updateState({ showPassword: !showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <TextField
          label={'Email'}
          variant={'filled'}
          value={form?.email ?? ''}
          onChange={(e) => {
            updateForm({ email: e.target.value });
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') await executeLogin();
          }}
        />
        <TextField
          label={'Password'}
          variant={'filled'}
          type={showPassword ? 'text' : 'password'}
          value={form?.password ?? ''}
          onChange={(e) => {
            updateForm({ password: e.target.value });
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') await executeLogin();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <ButtonXd icon={<ArrowForward/>} onClick={executeLogin}
                state={reqState} disabled={!form?.email || !form?.password}
                trailingIcon>
        Login
      </ButtonXd>
    </>
  );
}
