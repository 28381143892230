import React, { useEffect, useState } from 'react';
import { type PerformanceTable } from '../models/BacktestModel';
import GenericDateRangeSelector from './GenericDateRangeSelector';
import dayjs from 'dayjs';
import { Refresh } from '@mui/icons-material';
import GenericStatisticTableWrapper from './GenericStatisticTableWrapper';
import GenericStatisticTableTile from './GenericStatisticTableTile';
import { formatDate } from '../helpers/helper';
import { useSearchParams } from 'react-router-dom';
import CustomAccordion from './CustomAccordion';
import { type ApiReqStateModel } from '../models/ApiReqStateModel';
import { Alert } from '@mui/material';
import ButtonXd from './dashboardContent/ButtonXd';

interface Props {
  strategy: string;
  benchmark: string;
  iPerfTable: PerformanceTable;
  perfTable: PerformanceTable;
  refreshCallback: (start: string, end: string) => void;
  title?: string;
  benchmarkLabel?: string;
  displayMsg?: string;
  state?: ApiReqStateModel;
}

interface State {
  start?: number;
  end?: number;
  iStart?: number;
  iEnd?: number;
  hasValidationErrors?: boolean;
}

export default function PerformanceDatePicker(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<State>({});
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };
  const {start, end, hasValidationErrors} = state;

  const refreshHandler = (st: number | undefined, en: number | undefined, preserveTime?: boolean) => {
    if (!st || !en) return;
    updateState({iStart: st, iEnd: en});
    // The start and end day must be converted to 00:00:00 at UTC time otherwise backend will complain,
    // so we subtract it by the offset
    const startTs = preserveTime ? st.toString() : (st - new Date(st).getTimezoneOffset() * 60 * 1e3).toString();
    const endTs = preserveTime ? en.toString() : (en - new Date(en).getTimezoneOffset() * 60 * 1e3).toString();
    props.refreshCallback(startTs, endTs);

    const params = {
      ...Object.fromEntries([...searchParams]),
      start: startTs,
      end: endTs,
    };
    setSearchParams(params, {replace: true});
  };

  useEffect(() => {
    if (!props.iPerfTable?.[props.strategy]) return;
    const startTs =
      dayjs(props.iPerfTable?.[props.strategy]['Start date'] as string).unix() *
      1e3;
    const endTs =
      dayjs(props.iPerfTable?.[props.strategy]['End date'] as string).unix() *
      1e3;
    updateState({
      start: startTs,
      end: endTs,
      iStart: startTs,
      iEnd: endTs,
    });

    if (searchParams.get('start') && searchParams.get('end')) {
      const searchStartTs = Number.parseInt(searchParams.get('start') ?? '');
      const searchEndTs = Number.parseInt(searchParams.get('end') ?? '');
      if (
        !!searchStartTs &&
        !!searchEndTs &&
        searchStartTs > 0 &&
        searchEndTs > 0 &&
        searchStartTs < searchEndTs
      ) {
        updateState({ start: searchStartTs, end: searchEndTs });
        refreshHandler(searchStartTs, searchEndTs, true);
        return;
      }
    }

    const params = {
      ...Object.fromEntries([...searchParams]),
    };
    delete params.start;
    delete params.end;
    setSearchParams(params, { replace: true });
  }, [props.iPerfTable]);

  const timeStringOptions = {
    hour: undefined,
    minute: undefined,
    timeZoneName: undefined,
  };

  return (
    <CustomAccordion
      header={<h2>{props.title ?? `Performance (${props.strategy})`}</h2>}
      expanded={Object.keys(props.iPerfTable).length > 0}
    >
      <div className={'flex flex-col gap-4 rounded-lg'}>
        {Object.entries(props.iPerfTable).length > 0 && (
          <div>
            {Object.entries(props.iPerfTable).length > 0 && (
              <div className={'flex gap-4 items-start flex-wrap'}>
                <GenericDateRangeSelector
                  minDate={
                    dayjs(
                      props.iPerfTable?.[props.strategy]['Start date'] as string
                    ).unix() * 1e3
                  }
                  maxDate={
                    dayjs(
                      props.iPerfTable?.[props.strategy]['End date'] as string
                    ).unix() * 1e3
                  }
                  start={start}
                  end={end}
                  onValidationError={(hasValidationErrors) => {
                    updateState({ hasValidationErrors });
                  }}
                  onChange={(obj) => {
                    updateState(obj);
                  }}
                />
                <ButtonXd
                  icon={<Refresh />}
                  disabled={hasValidationErrors}
                  onClick={() => {
                    refreshHandler(start, end);
                  }}
                  state={props.state}
                  growOnMobile
                >Update</ButtonXd>
                <div className={'w-full h-0 border-t'} />
              </div>
            )}
          </div>
        )}
        {Object.entries(props.iPerfTable).length === 0 && (
          <Alert severity={'info'}>Data is not available</Alert>
        )}
        {Object.entries(props.iPerfTable).length > 0 &&
          props.perfTable?.[props.strategy] &&
          props.perfTable[props.benchmark] && (
            <>
              <p>
                <>
                  Statistics for{' '}
                  {formatDate(
                    props.perfTable[props.strategy]['Start date'] as string,
                    timeStringOptions
                  )}{' '}
                  to{' '}
                  {formatDate(
                    props.perfTable[props.strategy]['End date'] as string,
                    timeStringOptions
                  )}
                  :
                </>
              </p>
              <GenericStatisticTableWrapper>
                {Object.entries(props.perfTable[props.strategy])
                  .filter((e) => !e[0].includes('date'))
                  .map((e) => (
                    <GenericStatisticTableTile
                      key={e[0]}
                      label={e[0]}
                      primaryValue={e[1]}
                      secValue={props.perfTable?.[props.benchmark][e[0]]}
                      secLabel={props.benchmarkLabel ?? props.benchmark}
                    />
                  ))}
              </GenericStatisticTableWrapper>
            </>
          )}
        {props.displayMsg && <p>{props.displayMsg}</p>}
      </div>
    </CustomAccordion>
  );
}