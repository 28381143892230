import React from 'react';
import { PageRoutes } from '../../../enums/enums';
import ResponsiveGridWrapper from '../../../components/dashboardContent/ResponsiveGridWrapper';
import ActionLinkButton from '../../../components/dashboardContent/ActionLinkButton';
import {DocumentChartBarIcon, LockClosedIcon, PencilSquareIcon} from '@heroicons/react/24/outline';
import {useAppSelector} from '../../../hooks/hooks';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';


export default function UserManagementIndex() {
  const {firstName, lastName, email} = useAppSelector((state) => state.user);
  
  return (
    <Page>
      <PageModule>
        <PageModuleTitle title={`${firstName} ${lastName}`} subtitle={email}/>
        <ResponsiveGridWrapper>
          <ActionLinkButton label='Backtest access' to={PageRoutes.USERS_BACKTEST}>
            <DocumentChartBarIcon/>
          </ActionLinkButton>
          <ActionLinkButton label='Edit user details' to={PageRoutes.USERS_EDIT}>
            <PencilSquareIcon/>
          </ActionLinkButton>
          <ActionLinkButton label='Security & Privacy' to={PageRoutes.USERS_DELETE}>
            <LockClosedIcon/>
          </ActionLinkButton>
        </ResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}
