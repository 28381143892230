import React, { useEffect, useState } from 'react';
import ResponsiveGridWrapper from '../../components/dashboardContent/ResponsiveGridWrapper';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import type { ApiReqStateModel } from '../../models/ApiReqStateModel';
import NewsService from '../../services/newsService';
import { updateState } from '../../helpers/helper';
import { Alert } from '@mui/material';

interface State {
  newsURI?: string;
  newsURIState: ApiReqStateModel;
}

export default function PublishingIndex() {
  const [state, setState] = useState<State>({
    newsURIState: {isLoading: true, isError: false}
  });

  useEffect(() => {
    (async() => {
      try {
        updateState<State>({newsURIState: {isLoading: true, isError: false}}, state, setState);
        const uri = await NewsService.getNewsURI();
        updateState<State>({newsURI: uri.uri, newsURIState: {isLoading: false, isError: false}}, state, setState);
      } catch (e) {
        updateState<State>({newsURIState: {isLoading: false, isError: true}}, state, setState);
      }
    })();
  }, []);

  return (
    <Page>
      <PageModule>
        <PageModuleTitle title={'Blog Management'}/>
        {
          state.newsURIState.isError &&
          <Alert severity={'error'}>Failed to connect with news service</Alert>
        }
        <ResponsiveGridWrapper>
          <>
            {
              state.newsURI &&
              <ActionLinkButton
                label={'Ghost Console'}
                href={`${state.newsURI}/ghost`}
                target={'_blank'}>
                <ArrowTopRightOnSquareIcon />
              </ActionLinkButton>
            }
          </>
        </ResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}
