import React from 'react';
import { OpenInNew } from '@mui/icons-material';

interface CompanyInfoDetailProps {
  desc: string | undefined;
  icon: JSX.Element;
  className?: string;
  isHyperlink?: boolean;
}

export default function CompanyInfoDetail({desc, icon, className, isHyperlink}: CompanyInfoDetailProps) {
  return (
    <>
      {
        desc && desc !== 'Not disclosed' &&
          <span className={`flex gap-2 items-top ${className}`}>
            {icon}
            {
              !isHyperlink &&
              <span className={'-mt-1'}>{desc}</span>
            }
            {
              isHyperlink && <a
                href={desc}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <span>{desc}</span>{' '}
                <OpenInNew />
              </a>
            }
          </span>
      }
    </>
  );
}
