import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import {
  addBreadcrumbTextReplacement,
  purgeBreadcrumbTextReplacementDictForOutlets,
} from '../../store/appSlice';
import { Outlet } from 'react-router-dom';
import {PageRoutes} from '../../enums/enums';

export default function BacktestOutlet() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([PageRoutes.BACKTEST_OUTLET, 'Backtests']));
    return () => {
      dispatch(purgeBreadcrumbTextReplacementDictForOutlets());
    };
  }, []);

  return <Outlet />;
}
