import React from 'react';

interface SidebarButtonsProps {
  label: string;
  onClick?: () => void;
  isSelected: boolean;
  icon?: JSX.Element;
  iconSelected?: JSX.Element;
}

export default function SidebarButton({
  label,
  onClick,
  isSelected,
  icon,
  iconSelected,
}: SidebarButtonsProps) {
  return (
    <div
      className={'block'}
      onClick={() => {
        onClick?.();
      }}
    >
      <li
        className={
          `${
            isSelected
              ? 'bg-teal-ppt-1'
              : ''
          } cursor-pointer md:hover:bg-teal text-white ` +
          'pl-3 pr-4 py-2 md:text-xl flex gap-3 rounded-xl items-center overflow-hidden font-normal'
        }
      >
        {icon != null && (
          <span className={'flex-none w-6 h-8 py-1'}>
            {iconSelected != null && isSelected ? iconSelected : icon}
          </span>
        )}
        <h6 className={'flex-none capitalize m-0'}>{label}</h6>
      </li>
    </div>
  );
}
