import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import {
  addBreadcrumbTextReplacement,
  purgeBreadcrumbTextReplacementDictForOutlets,
} from '../../store/appSlice';
import { Outlet } from 'react-router-dom';
import {PageRoutes} from '../../enums/enums';

export default function UserManagementOutlet() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      addBreadcrumbTextReplacement([
        PageRoutes.USERS_OUTLET,
        'User Management',
      ])
    );
    return () => {
      dispatch(purgeBreadcrumbTextReplacementDictForOutlets());
    };
  }, [dispatch]);

  return <Outlet />;
}
