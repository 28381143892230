import React, { useEffect, useState } from 'react';
import CustomDatePicker from './CustomDatePicker';
import dayjs from 'dayjs';

interface GenericDateRangeSelectorProps {
  minDate?: number;
  maxDate?: number;
  start?: number;
  end?: number;
  onChange: (obj: Partial<Record<'start' | 'end', number>>) => void;
  onValidationError: (hasErrors: boolean) => void;
  limitWidth?: boolean;
}

interface State {
  errorMessage?: string,
}

/***
 *
 * @param minDate min date as UNIX timestamp in milliseconds
 * @param maxDate max date as UNIX timestamp in milliseconds
 * @param start UNIX timestamp in milliseconds
 * @param end UNIX timestamp in milliseconds
 * @param onDateRangeChange callback when date range changes
 * @param onValidationError callback when a validation error is found/resolved
 * @param limitWidth boolean to limit the width
 * @constructor
 */
export default function GenericDateRangeSelector({minDate, maxDate, start, end, onChange, onValidationError, limitWidth}: GenericDateRangeSelectorProps) {
  const classNames = ['w-full'];

  if (limitWidth === undefined || limitWidth) {
    classNames.push('sm:w-44');
  }

  const [state, setState] = useState<State>({});
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { errorMessage } = state;

  useEffect(() => {
    if (start && end && (end < start || end === start)) {
      updateState({ errorMessage: 'Date must be after start date' });
      onValidationError(true);
    } else {
      updateState({ errorMessage: undefined });
      onValidationError(false);
    }
  }, [start, end]);

  const minDateDayjs = minDate ? dayjs(minDate) : undefined;
  const maxDateDayjs = maxDate ? dayjs(maxDate) : undefined;

  return (
    <div className={'flex gap-4 gap-y-2 flex-wrap flex-grow sm:flex-shrink'}>
      <CustomDatePicker
        label={'Start Date'}
        value={dayjs(start)}
        minDate={minDateDayjs}
        maxDate={maxDateDayjs}
        className={classNames.join(' ')}
        onChange={(e) => {
          onChange({ start: dayjs(e).unix() * 1000 });
        }}
      />
      <CustomDatePicker
        label={'End Date'}
        value={dayjs(end)}
        minDate={minDateDayjs}
        maxDate={maxDateDayjs}
        isError={!!errorMessage}
        helperText={errorMessage}
        className={classNames.join(' ')}
        onChange={(e) => {
          onChange({ end: dayjs(e).unix() * 1000 });
        }}
      />
    </div>
  );
}