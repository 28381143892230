import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { ViewsEnumDeprecate } from '../../enums/viewsEnumDeprecate';
import SidebarButton from './SidebarButton';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid';
import { toggleSidebar, updateActiveView } from '../../store/appSlice';
import { useLocation } from 'react-router-dom';
import SidebarContents from './SidebarContents';

export default function Sidebar() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const dashboard = useAppSelector((state) => state.dashboard);
  const sidebar = useAppSelector((state) => state.dashboard.sidebar);

  useEffect(() => {
    const enumString = location.pathname.split(
      '/'
    )[1] as unknown as ViewsEnumDeprecate;
    dispatch(updateActiveView(ViewsEnumDeprecate[enumString]));
  }, [location.pathname, dispatch]);

  const minimizeSidebarIcon = sidebar.isExpanded ? (
    <ChevronDoubleLeftIcon />
  ) : (
    <ChevronDoubleRightIcon />
  );

  return (
    <>
      <div
        className={
          'bg-teal-2 text-white relative whitespace-nowrap select-none cursor-default sticky hidden md:block'
        }
        style={{
          minWidth: sidebar.isExpanded ? '14rem' : '4rem',
          width: sidebar.isExpanded ? '14rem' : '4rem',
          height: `${dashboard.dim.h}px`,
          top: `${window.innerHeight - dashboard.dim.h}px`,
        }}
      >
        <div
          className={
            'flex flex-col p-2 py-3 overflow-y-auto overflow-x-hidden text-sm'
          }
          style={{ height: `calc(${dashboard.dim.h}px - 4rem)` }}
        >
          <ul className={'flex flex-col gap-2'}>
            <SidebarContents sidebarIsExpanded={sidebar.isExpanded} />
          </ul>
        </div>
        <div className={'fixed bottom left-0 bottom-0 bg-teal-2'}
             style={{width: sidebar.isExpanded ? '14rem' : '4rem'}}
        >
          <div className={'p-2'}>
            <SidebarButton
              label={'Minimize'}
              onClick={() => dispatch(toggleSidebar())}
              isSelected={false}
              icon={minimizeSidebarIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
}
