import React from 'react';
import {Navigate, useLocation, useSearchParams} from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  // Redirects non-logged in users to the welcome page where they can login or register.
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const jwt = useAppSelector((state) => state.authentication.jwt);

  return (
    <>
      {jwt === '' && <Navigate to={`/login?ref=${searchParams.get('ref') ?? location.pathname + location.search}`} replace />}
      {jwt && children}
    </>
  );
}
