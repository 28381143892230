import React from 'react';
import DataNotAvailable from './alerts/DataNotAvailable';
import GenericTableColumn from './GenericTableColumn';
import GenericTableTile from './GenericTableTile';

interface TableProps {
    data: Record<string, Record<string, number | string>>;
    decimals: number;
}

/***
 * Generic Table with Index to create regular tables with indexes.
 * @param data Table data provided in the form of {index1: {col1: value1, col2: value2}}
 * @param decimals Integer value to determine the decimals of the number values
 */


export default function GenericTableWithIndex({ data, decimals }: TableProps) {
    const indexes = Object.keys(data);

    if (indexes.length === 0) {
        return <DataNotAvailable/>;
    }

    return (
        <table className={'table-auto w-full'}>
            <thead>
            <tr>
                <th className='px-4 py-2 opacity-80'></th>
                {indexes.length > 0 &&
                    Object.keys(data[indexes[0]]).map((column, index) => (
                        <GenericTableColumn key={index} column={column} />
                    ))}
            </tr>
            </thead>
            <tbody>
            {indexes.map((indexKey, rowIndex) => (
                <tr key={rowIndex}>
                    <td className='border px-4 py-2 text-center'>
                        {
                            isNaN(parseInt(indexKey[0])) ?
                                indexKey.charAt(0).toUpperCase() + indexKey.slice(1).toLowerCase() :
                                indexKey
                        }
                    </td>
                    {Object.entries(data[indexKey]).map(([column, value], index) => (
                        <GenericTableTile key={index} column={column} value={value} decimals={decimals} />
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
}
