import BaseApiService from './baseApiService';
import type {
    HeaderInfo,
    AvgAnnualTotalReturns,
    MarketCyclePerformance,
    CalendarYearlyReturns,
    GrowthInvestment,
    DownQuartersPerformance,
    Fees,
    TopPositions,
    DownMarketPerformances,
    SectorAllocation
} from '../models/FactsheetModel';

export default class FactsheetServices extends BaseApiService {
    static async getHeaderValues() {
        return (await super.get<HeaderInfo>('factsheet/header')).getData();
    }

    static async getAvgAnnualTotalReturnValues(returnInterval: string = 'Q') {
        return (await super.get<AvgAnnualTotalReturns>('factsheet/avgAnnualTotalReturns', {
            returnInterval,
        })).getData();
    }

    static async getMarketCyclePerformanceValues(startDate: string = '2007-07-13', endDate: string = '2009-03-09', returnTs: boolean = true) {
        return (await super.get<MarketCyclePerformance>('factsheet/marketCyclePerformance', {
            startDate, endDate, returnTs
        })).getData();
    }

    static async getCalendarYearlyReturns(lastNYears: number = 10) {
        return (await super.get<CalendarYearlyReturns>('factsheet/calendarYearlyReturns', {
            lastNYears
        })).getData();
    }

    static async getGrowthInvestment() {
        return (await super.get<GrowthInvestment>('factsheet/growthInvestment')).getData();
    }

    static async getDownMarketPerformances() {
        return (await super.get<DownMarketPerformances>('factsheet/downMarketPerformances')).getData();
    }

    static async getDownQuartersPerformance(nQuarters: number = 10) {
        return (await super.get<DownQuartersPerformance>('factsheet/downQuartersPerformance', {
            nQuarters
        })).getData();
    }

    static async getTopPositions(nPositions: number = 10) {
        return (await super.get<TopPositions>('factsheet/topPositions', {
            nPositions
        })).getData();
    }

    static async getFees() {
        return (await super.get<Fees>('factsheet/fees')).getData();
    }

    static async getSectorAllocation() {
        return (await super.get<SectorAllocation>('factsheet/sectorAllocation')).getData();
    }

    static async getFactsheetDisclaimer() {
        return (await super.get<{ disclaimer: string }>('factsheet/disclaimer')).getData();
    }
}