import type SuccessfulLogin from '../models/LoginResponseModel';
import { type TwoFaResponse } from '../models/LoginResponseModel';
import ApiService from './apiService';

export default class AuthenticationService extends ApiService {
  static async login(email: string, password: string) {
    return (
      await super.post<SuccessfulLogin>('auth', 'login', { email, password })
    ).getData();
  }

  static async refreshToken(jwt: string){
    return (
      await super.get<{ token: string }>(
        'auth', 'refreshToken',
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
    ).getData().token;
  }

  static async send2Fa(
    email: string,
    password: string,
    code: string
  ) {
    return (
      await super.post<TwoFaResponse>('auth', 'twoFaCode', {
        code,
        password,
        email,
      })
    );
  }
}
