import React from 'react';

interface ColumnProps {
    column: string;
}

/***
 * Generic Table Column to be reused in indexes and non-indexes tables.
 * @param column Column name to be formatted
 */

export default function GenericTableColumn({ column }: ColumnProps) {
    return (
        <th className='px-4 py-2 text-sm opacity-80 uppercase font-bold'>
            {column.includes('_') ? column.replaceAll('_', ' ') : column}
        </th>
    );
}
