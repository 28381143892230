import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AutoBreadcrumbs from '../components/dashboardContent/AutoBreadcrumbs';
import ManualBreadcrumbs from '../components/dashboardContent/ManualBreadcrumbs';
import { useAppSelector } from '../hooks/hooks';

export default function DashboardOutlet() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isContentOverflowing, setIsContentOverflowing] =
    useState<boolean>(false);
  const breadcrumbLength = useAppSelector(
    (state) => state.dashboard.breadcrumbs.length
  );

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsContentOverflowing(true);
    } else {
      setIsContentOverflowing(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navigate, location.pathname]);

  return (
    // Not sure why, but adding 'w-0' works
    <div className={'flex-grow bg-white relative w-0'}>
      {breadcrumbLength === 0 ? (
        <AutoBreadcrumbs isContentOverflowing={isContentOverflowing} />
      ) : (
        <ManualBreadcrumbs isContentOverflowing={isContentOverflowing} />
      )}
      <Outlet />
    </div>
  );
}
