import React from 'react';
import WidthWrapper from './WidthWrapper';
import './pageModule.css';
import { type StatefulApiResponseContainerModel } from '../../models/ApiReqStateModel';
import StatefulApiResponseContainer from '../StatefulApiResponseContainer';

interface pageModuleProps extends Partial<StatefulApiResponseContainerModel> {
  children: React.ReactNode;
  background?: React.ReactNode;
  className?: string;
  gap?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function PageModule(props: pageModuleProps) {
  const wrapperClassNames = ['w-full'];
  const classNames = ['flex flex-col'];

  if ((props.className && (props.className.includes('py-') ||
    props.className.includes('pt-') ||
    props.className.includes('pb-')))) {
    wrapperClassNames.push('');
  } else {
    wrapperClassNames.push('py-8');
  }

  switch (props.gap) {
    case 'xs':
      classNames.push('gap-2');
      break;
    case 'xl':
      classNames.push('gap-12');
      break;
    case 'sm':
      classNames.push('gap-4');
      break;
    case 'lg':
      classNames.push('gap-8');
      break;
    case 'md':
    default:
      classNames.push('gap-6');
      break;
  }

  if (props.className) {
    classNames.push(props.className);
  }

  const childrenElement = (
    <div className={classNames.join(' ')}>
      {props.children}
    </div>
  );

  const widthWrapperElement = (
    <WidthWrapper className={wrapperClassNames.join(' ')}>
      {
        props.state &&
        <StatefulApiResponseContainer {...props} state={{isLoading: false, isError: props.state?.isError ?? false}}>
          {childrenElement}
        </StatefulApiResponseContainer>
      }
      {
        !props.state && childrenElement
      }
    </WidthWrapper>
  );

  const elementWithBackground = (
    <div className={'xd-page-module relative z-0'}>
      {
        props.background && props.background
      }
      {
        widthWrapperElement
      }
    </div>
  );

  const elementWithoutBackground = (
    <div>
      {widthWrapperElement}
    </div>
  );

  const moduleElements = (
    <>
      {
        !props.background && elementWithoutBackground
      }
      {
        props.background && elementWithBackground
      }
    </>
  );

  return (
    <>
      {
        props.state &&
        <StatefulApiResponseContainer state={{isLoading: props.state?.isLoading ?? false, isError: false}}>
          {moduleElements}
        </StatefulApiResponseContainer>
      }
      {
        !props.state && (props.displayWhen === undefined || props.displayWhen) && moduleElements
      }
    </>
  );
}