import React from 'react';

interface pageModuleTitleProps {
  title: string | React.ReactNode;
  level?: 'h1' | 'h2' | 'h3' | 'h4'
  desc?: string;
  subtitle?: string;
  highlight?: boolean;
}

export default function PageModuleTitle({title, subtitle, desc, level, highlight}: pageModuleTitleProps) {
  const titleClassNames: string[] = [];

  if (highlight) {
    titleClassNames.push('text-teal-1');
  }

  let element: React.ReactNode;

  switch (level) {
    case 'h2':
      element = (<h2 className={titleClassNames.join(' ')}>{title}</h2>);
      break;
    case 'h3':
      element = (<h3 className={titleClassNames.join(' ')}>{title}</h3>);
      break;
    case 'h4':
      element = (<h4 className={titleClassNames.join(' ')}>{title}</h4>);
      break;
    case 'h1':
    default:
      element = (<h1 className={titleClassNames.join(' ')}>{title}</h1>);
      break;
  }

  return (
    <>
      {
        title &&
        <div className={'flex flex-col'}>
          {
            subtitle &&
            <p className={'text-sm uppercase font-[600]'}>{subtitle}</p>
          }
          {
            title && title !== '' && element
          }
          {
            desc && <p>{desc}</p>
          }
        </div>
      }
    </>
  );
}