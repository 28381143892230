import React from 'react';
import { type ApiReqStateModel } from '../../models/ApiReqStateModel';
import { Refresh } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';

interface BorderedButtonProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  trailingIcon?: boolean;
  state?: ApiReqStateModel;
  onClick?: () => void;
  growOnMobile?: boolean;
  sxColor?: string;
  sxColor80Pct?: string;
  disabled?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
}

export default function ButtonXd({
  children,
  icon,
  className,
  onClick,
  trailingIcon,
  state,
  growOnMobile,
  sxColor,
  sxColor80Pct,
  disabled,
  color,
}: BorderedButtonProps) {
  const classNames = ['flex-none'];

  if (growOnMobile) {
    classNames.push('w-full md:w-auto');
  }

  if (className) {
    classNames.push(className);
  }

  let receivedState = {...state};

  if (!receivedState) {
    receivedState = { isLoading: false, isError: false };
  }

  if (receivedState.isLoading) {
    classNames.push('pointer-events-none');
  }

  const iconSelection = (
    <>
      {
        receivedState.isLoading && <div className={'mt-1 leading-3 px-0.5'}><CircularProgress size={'1rem'} color={'inherit'} className={'leading-3'}/></div>
      }
      {
        !receivedState.isLoading && !receivedState.isError && icon
      }
      {
        !receivedState.isLoading && receivedState.isError && <Refresh/>
      }
    </>
  );

  const iconElement = (
    <span className={'text-xl -mt-1'}>{iconSelection}</span>
  );

  return (
    <Button
      sx={{
        paddingX: children ? '1.5rem' : '1.2rem',
        paddingY: '0.75rem',
        minWidth: '0',
        color: sxColor ?? 'auto',
        borderColor: sxColor80Pct ?? 'auto',
        ':hover': {
          borderColor: sxColor ?? 'auto',
        },
      }}
      disabled={disabled}
      onClick={() => {
        onClick?.();
      }}
      color={color}
      variant={'outlined'}
      className={classNames.join(' ')}
    >
      <div className={'flex gap-1 items-center'}>
        {!trailingIcon && iconElement}
        {children && <span className={'py-[0.2rem] font-bold'}>{children}</span>}
        {trailingIcon && iconElement}
      </div>
    </Button>
  );
}
