import React from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';
import {useAppSelector} from '../../hooks/hooks';
import {PageRoutes} from '../../enums/enums';

export default function NonRequireAuth({children}: { children: JSX.Element }) {
  // Automatically redirects logged in users to their dashboard or desired path as defined by the ref params
  const [searchParams] = useSearchParams();
  const jwt = useAppSelector((state) => state.authentication.jwt);

  if (jwt !== '') {
    return <Navigate to={`${searchParams.get('ref') ?? PageRoutes.DASHBOARD_OUTLET}`} replace/>;
  }

  return children;
}
