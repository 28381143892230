import React from 'react';
import {Logout, Person} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {logout} from '../../store/authenticationSlice';
import { useNavigate } from 'react-router-dom';

export default function LogoutElement() {
  const { user } = useAppSelector((state) => state.authentication);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      {
        user &&
          <span className={'flex gap-2 flex-none items-center'}><Person/><span>{user.email}</span></span>
      }
      <a onClick={() => {
        dispatch(logout());
        navigate('/');
      }}
        className={'flex gap-1 items-center opacity-80 md:opacity-100 text-white md:text-teal-1 cursor-pointer'}>
        <Logout />
        <span>Log out</span>
      </a>
    </>
  );
}