import React from 'react';
import GenericTableWithIndex from './GenericTableWithIndex';
import GenericTableWithoutIndex from './GenericTableWithoutIndex';

type TableWithoutIndex = Record<string, number | string>;
type TableWithIndex = Record<string, Record<string, number | string>>;

interface GenericTableWrapperProps {
    data: TableWithoutIndex | TableWithIndex;
    withIndexes?: boolean;
    decimals?: number;
}

/***
 * Generic Table Wrapper to create different table types based on the preference.
 * @param data Table data provided in the form of {col1: value1, col2: value2} for non-indexed
 * and {index1: {col1: value1, col2: value2}} for indexed tables
 * @param withIndexes Optional value to determine if the tables has indexes or not
 * @param decimals Optional value to define the decimals that numbers will be formatted to (default = 2)
 */

export default function GenericTableWrapper({ data, withIndexes = false, decimals = 2 }: GenericTableWrapperProps) {
    return (
        withIndexes ?
            <GenericTableWithIndex data={data as TableWithIndex} decimals={decimals} />
            :
            <GenericTableWithoutIndex data={data as TableWithoutIndex} decimals={decimals} />
    );
}
